import PropTypes from "prop-types";

const SocialSignup = ({ className = "" }) => {
  return (
    <div
      className={`absolute top-[0px] left-[-289px] w-[2351.9px] flex flex-col items-center justify-end pt-[342px] pb-[341.7px] box-border min-h-[969px] z-[1] text-center text-5xl text-color-text-charcoal font-callout-body ${className}`}
    >
      <img
        className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-contain"
        alt=""
        src="/background-waves-1@2x.png"
      />
      <div className="relative top-[-70px] flex flex-col items-center justify-center gap-[20px] z-[1] w-full max-w-[1200px] mx-auto">
        <b className="tracking-[-0.02em] text-center z-[1] mq450:text-lgi">
          Connect with us on social media
        </b>
        <div className="flex flex-row items-center justify-center gap-[30px] z-[1] mt-4">
          <a
            href="https://ae.linkedin.com/company/3-shepherd"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer hover:opacity-80 transition-opacity"
          >
            <img
              className="h-[24.5px] w-[24.6px] relative"
              loading="lazy"
              alt="LinkedIn"
              src="/linekdin-icon.svg"
            />
          </a>
          <img
            className="h-[26px] w-7 relative min-h-[26px]"
            loading="lazy"
            alt=""
            src="/x-icon.svg"
          />
        </div>
      </div>
    </div>
  );
};

SocialSignup.propTypes = {
  className: PropTypes.string,
};

export default SocialSignup;