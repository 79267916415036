import React, { useEffect, useMemo, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@remixicon/react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Badge,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
  Flex,
  Select,
  SelectItem,
  TextInput,
} from "@tremor/react";
import api from '../../services/api';
import { useAuth } from '../../components/AuthProvider';


const List = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const { getUser } = useAuth();
  const userData = getUser();
  const merchantId = userData?.id;
  const ordersPerPage = 5;

  useEffect(() => {
    const fetchShipmentData = async () => {
      try {
        const response = await api.get(`/business/${merchantId}/shipments`);
        setRows(response.data);
      } catch (error) {
        console.error('Error fetching shipment data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchShipmentData();
  }, [merchantId]);

  const filteredData = useMemo(() => {
    return rows.filter(row => {
      const matchesStatus = statusFilter ? row.status === statusFilter : true;
      const matchesSearch = searchTerm ? row.order_id.includes(searchTerm) || row.tracking_number.includes(searchTerm) : true;
      return matchesStatus && matchesSearch;
    });
  }, [rows, statusFilter, searchTerm]);

  const columns = useMemo(
    () => [
      {
        header: "Order ID",
        accessorKey: "order_id",
        meta: { align: "text-left" },
      },
      {
        header: "Tracking Number",
        accessorKey: "tracking_number",
        meta: { align: "text-left" },
      },
      {
        header: "Created At",
        accessorFn: (row) => new Date(row.createdAt).toLocaleString(),
        meta: { align: "text-left" },
      },
      {
        header: "Status",
        accessorKey: "status",
        meta: { align: "text-left" },
        cell: ({ getValue }) => (
          <Badge color={getStatusColor(getValue())}>{getValue()}</Badge>
        ),
      },
      {
        header: "Action",
        accessorKey: "action",
        meta: { align: "text-left" },
        cell: ({ row }) => (
          <Button
            variant="secondary"
            onClick={() => window.open(row.original.label_url, "_blank")}
          >
            Print Label
          </Button>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: ordersPerPage,
      },
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="mt-4 mb-4">
      <Title>Shipments</Title>

      {/* Filters */}
      <Flex className="mt-4 mb-4">
        <Select onValueChange={value => setStatusFilter(value)} value={statusFilter} placeholder="Filter by Status">
          <SelectItem value="">All</SelectItem>
          <SelectItem value="delivered">Delivered</SelectItem>
          <SelectItem value="processing">Processing</SelectItem>
          <SelectItem value="cancelled">Cancelled</SelectItem>
        </Select>
        <TextInput
          placeholder="Search by Order ID or Tracking Number"
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
          className="ml-2"
        />
      </Flex>

      <Table className="mt-5">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderCell key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Flex justifyContent="between" className="mt-4">
        <div className="text-sm text-gray-600">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <div className="inline-flex">
          <button
            className="bg-white border border-gray-300 rounded-full p-2 mx-1 hover:bg-gray-100 disabled:opacity-50"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <RiArrowLeftSLine />
          </button>
          <button
            className="bg-white border border-gray-300 rounded-full p-2 mx-1 hover:bg-gray-100 disabled:opacity-50"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <RiArrowRightSLine />
          </button>
        </div>
      </Flex>
    </Card>
  );
};

export default List;

function getStatusColor(status) {
  switch (status) {
    case "delivered":
      return "emerald";
    case "processing":
      return "blue";
    case "cancelled":
      return "red";
    default:
      return "gray";
  }
}