import React, { useEffect, useState } from "react";
import { Card, Title, BarChart } from "@tremor/react";
import api from '../../../services/api';

const dataFormatter = (number) => {
  return "AED " + Intl.NumberFormat("us").format(number).toString();
};

const AreaChartComponent = () => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await api.get('/orders/total-amount');
        
        // Check if response.data exists and has the expected structure
        if (!response.data || !response.data.data) {
          throw new Error('Invalid data structure received from API');
        }

        const formattedData = Array.isArray(response.data.data) 
          ? response.data.data.map(item => ({
              date: new Date(item.month).toLocaleString('default', { 
                month: 'short', 
                year: 'numeric' 
              }),
              "Amount Received": parseFloat(item.totalAmount || 0)
            }))
          : []; // Return empty array if data is not an array

        setChartData(formattedData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChartData();
  }, []);

  if (loading) {
    return (
      <Card className="mt-4">
        <Title>Total Order Amount over 3 Months (AED)</Title>
        <div className="h-72 mt-4 flex items-center justify-center">
          <div className="animate-pulse text-gray-500">Loading chart data...</div>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="mt-4">
        <Title>Total Order Amount over 3 Months (AED)</Title>
        <div className="h-72 mt-4 flex items-center justify-center">
          <div className="text-red-500">
            Failed to load chart data. Please try again later.
          </div>
        </div>
      </Card>
    );
  }

  if (!chartData.length) {
    return (
      <Card className="mt-4">
        <Title>Total Order Amount over 3 Months (AED)</Title>
        <div className="h-72 mt-4 flex items-center justify-center">
          <div className="text-gray-500">No data available for the selected period.</div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="mt-4">
      <Title>Total Order Amount over 3 Months (AED)</Title>
      <BarChart
        className="h-72 mt-4"
        data={chartData}
        index="date"
        categories={["Amount Received"]}
        colors={["sky"]}
        valueFormatter={dataFormatter}
        yAxisWidth={80}
        showAnimation={true}
      />
    </Card>
  );
};

export default AreaChartComponent;