import React, { useEffect, useState } from "react";
import { StatusOnlineIcon } from "@heroicons/react/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
  TextInput,
  Select,
  SelectItem,
  Button,
} from "@tremor/react";
import api from '../../../services/api'; // Adjust the import path to your api service

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [idFilter, setIdFilter] = useState("");
  const [userIdFilter, setUserIdFilter] = useState("");
  const [merchantIdFilter, setMerchantIdFilter] = useState("");
  const [orderIdFilter, setOrderIdFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await api.get('/transactions');
        setData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    let tempData = data;

    if (idFilter) {
      tempData = tempData.filter(transaction =>
        transaction.transaction_id.toString().includes(idFilter)
      );
    }

    if (userIdFilter) {
      tempData = tempData.filter(transaction =>
        transaction.user_id.toString().includes(userIdFilter)
      );
    }

    if (merchantIdFilter) {
      tempData = tempData.filter(transaction =>
        transaction.merchant_id.toString().includes(merchantIdFilter)
      );
    }

    if (orderIdFilter) {
      tempData = tempData.filter(transaction =>
        transaction.order_id.toString().includes(orderIdFilter)
      );
    }

    if (amountFilter) {
      tempData = tempData.filter(transaction =>
        transaction.amount_cleared.toString().includes(amountFilter)
      );
    }

    if (statusFilter) {
      tempData = tempData.filter(transaction => transaction.status === statusFilter);
    }

    if (dateFilter) {
      tempData = tempData.filter(transaction => 
        new Date(transaction.updatedAt).toLocaleDateString() === new Date(dateFilter).toLocaleDateString()
      );
    }

    setFilteredData(tempData);
  }, [idFilter, userIdFilter, merchantIdFilter, orderIdFilter, amountFilter, statusFilter, dateFilter, data]);

  const getStatusColor = (status) => {
    switch (status) {
      case "processed!":
        return "emerald";
      case "cancelled":
        return "red";
      case "refunded":
        return "blue";
      default:
        return "gray";
    }
  };

  return (
    <Card className="mt-4">
      <Title>Transactions</Title>
      <div className="flex space-x-4 mb-4">
        <TextInput
          placeholder="Filter by ID"
          value={idFilter}
          onChange={(e) => setIdFilter(e.target.value)}
        />
        <TextInput
          placeholder="Filter by User ID"
          value={userIdFilter}
          onChange={(e) => setUserIdFilter(e.target.value)}
        />
        <TextInput
          placeholder="Filter by Merchant ID"
          value={merchantIdFilter}
          onChange={(e) => setMerchantIdFilter(e.target.value)}
        />
        <TextInput
          placeholder="Filter by Order ID"
          value={orderIdFilter}
          onChange={(e) => setOrderIdFilter(e.target.value)}
        />
        <Select
          placeholder="Filter by Status"
          value={statusFilter}
          onChange={(value) => setStatusFilter(value)}
        >
          <SelectItem value="">All</SelectItem>
          <SelectItem value="delivered">Delivered</SelectItem>
          <SelectItem value="processing">Processing</SelectItem>
          <SelectItem value="cancelled">Cancelled</SelectItem>
          <SelectItem value="refunded">Refunded</SelectItem>
        </Select>
        <TextInput
          type="date"
          placeholder="Filter by Date"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
        <Button onClick={() => { setIdFilter(''); setUserIdFilter(''); setMerchantIdFilter(''); setOrderIdFilter(''); setAmountFilter(''); setStatusFilter(''); setDateFilter(''); }}>Clear Filters</Button>
      </div>
      <Table className="mt-5">
        <TableHead>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>User ID</TableHeaderCell>
            <TableHeaderCell>Merchant ID</TableHeaderCell>
            <TableHeaderCell>Order ID</TableHeaderCell>
            <TableHeaderCell>Amount Cleared</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Timestamp</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((transaction) => (
            <TableRow key={transaction.transaction_id}>
              <TableCell>{transaction.transaction_id}</TableCell>
              <TableCell>
                <Text>{transaction.user_id}</Text>
              </TableCell>
              <TableCell>
                <Text>{transaction.merchant_id}</Text>
              </TableCell>
              <TableCell>
                <Text>{transaction.order_id}</Text>
              </TableCell>
              <TableCell>
                <Text>AED {transaction.amount_cleared}</Text>
              </TableCell>
              <TableCell>
                <Badge color={getStatusColor(transaction.status)} icon={StatusOnlineIcon}>
                  {transaction.status}
                </Badge>
              </TableCell>
              <TableCell>
                <Text>{new Date(transaction.updatedAt).toLocaleString()}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default TableComponent;