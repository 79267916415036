import PropTypes from "prop-types";

const PayWith3SFace = ({ className = "" }) => {
  return (
    <div
      className={`w-full max-w-[531px] flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-6 sm:gap-4 md:gap-6 lg:gap-8 z-[1] text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-color-text-charcoal font-callout-body p-4 sm:p-6 ${className}`}
    >
      {/* Logo section */}
      <div className="shadow-[0px_0px_30px_rgba(0,_46,_66,_0.15)] rounded-3xl bg-color-background-white flex flex-row items-start justify-start p-3
        sm:transform sm:translate-x-[90px]">
        <img
          className="h-16 w-16 sm:h-[66px] sm:w-[66px] relative object-cover"
          alt="3S Logo"
          src="/3s-logo-icon-only-1@2x.png"
        />
      </div>

      {/* Text section */}
      <div className="flex flex-col items-center sm:items-start justify-center py-4 px-0
        sm:transform sm:translate-x-[80px]">
        <b className="relative tracking-[-0.02em] text-center sm:text-left">
          Pay with 3S
        </b>
        <div className="relative text-xs sm:text-sm tracking-[0.35em] font-medium text-color-text-gray whitespace-nowrap mt-1 text-center sm:text-left">
          COMING SOON
        </div>
      </div>

      {/* Button section */}
      <div className="flex flex-col items-center sm:items-end justify-center py-0 px-0 box-border min-w-[148px]
        sm:transform sm:translate-x-[50px] sm:flex-1">
        <button className="cursor-pointer border-none py-2.5 px-5 bg-color-background-lapis rounded-6xs flex flex-row items-center justify-center gap-2 hover:bg-opacity-90 transition-colors duration-300
          w-full sm:w-auto">
          <div className="relative text-base tracking-[-0.02em] font-semibold font-callout-body text-color-background-white text-left inline-block min-w-[104px]">
            Find out more
          </div>
          <div className="flex flex-row items-center justify-center py-0 px-[3px] box-border w-3.5 h-3.5">
            <img className="h-3.5 w-2 relative" alt="Arrow" src="/vector-2.svg" />
          </div>
        </button>
      </div>
    </div>
  );
};

PayWith3SFace.propTypes = {
  className: PropTypes.string,
};

export default PayWith3SFace;