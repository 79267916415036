import { useMemo } from "react";
import PropTypes from "prop-types";

const Icon = ({
  className = "",
  propWidth,
  propHeight,
  propPadding,
  vector,
  propHeight1,
  propWidth1,
  propTransform,
}) => {
  const iconStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
      padding: propPadding,
    };
  }, [propWidth, propHeight, propPadding]);

  const vectorIconStyle = useMemo(() => {
    return {
      height: propHeight1,
      width: propWidth1,
      transform: propTransform,
    };
  }, [propHeight1, propWidth1, propTransform]);

  return (
    <div
      className={`w-[8.4px] h-[8.4px] flex flex-row items-center justify-center ${className}`}
      style={iconStyle}
    >
      <img
        className="h-[9.6px] w-[9.6px] relative [transform:_rotate(6.6deg)]"
        loading="lazy"
        alt=""
        src={vector}
        style={vectorIconStyle}
      />
    </div>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  vector: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.string,
  propHeight: PropTypes.string,
  propPadding: PropTypes.string,
  propHeight1: PropTypes.string,
  propWidth1: PropTypes.string,
  propTransform: PropTypes.string,
};

export default Icon;
