import PropTypes from "prop-types";

const PaymentDescription = ({ className = "" }) => {
  return (
    <div
      className={`w-[596px] flex flex-col items-start justify-start gap-[24px] max-w-full text-center text-45xl text-color-text-charcoal font-callout-body ${className}`}
    >
      <div className="flex flex-col items-start justify-start max-w-full">
        <h1 className="m-0 w-[355px] relative text-inherit tracking-[-0.02em] font-bold font-inherit text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block max-w-full z-[2] mq450:text-19xl mq825:text-32xl">
          Pay with 3S
        </h1>
        <div className="relative text-5xl tracking-[-0.02em] text-left mq450:text-lgi">
          <p className="m-0 font-medium">The payments you make every day</p>
          <p className="m-0 font-extrabold">MADE SECURE</p>
        </div>
      </div>
      <div className="self-stretch relative text-5xl tracking-[-0.02em] font-medium text-left z-[3] mq450:text-lgi">
        With just one tap, you’re securing your online payments against
        transaction fraud.
      </div>
      <button className="cursor-pointer [border:none] py-2.5 px-5 bg-color-primary-bright-jade rounded-6xs flex flex-row items-start justify-start gap-[10px]">
        <div className="flex flex-col items-start justify-start pt-[2.5px] px-0 pb-0">
          <div className="h-3.5 flex flex-row items-center justify-center w-3.5">
            <img
              className="h-[15px] w-3.5 relative"
              alt=""
              src="/vector-3.svg"
            />
          </div>
        </div>
        <div className="relative text-base tracking-[-0.02em] font-semibold font-callout-body text-color-background-white text-left inline-block min-w-[127px]">
          See how we do it
        </div>
        <div className="hidden flex-row items-center justify-center w-3.5 h-3.5">
          <img className="h-3.5 w-3.5 relative" alt="" src="/vector-4.svg" />
        </div>
      </button>
    </div>
  );
};

PaymentDescription.propTypes = {
  className: PropTypes.string,
};

export default PaymentDescription;
