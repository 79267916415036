import React from 'react';
import PropTypes from "prop-types";
import Icon from "./Icon";

const Input1 = ({
  className = "",
  placeholderText = "Enter your email",
  errorInfo = "Error/info Text",
  required = false,
  description = "Description text",
  showErrorInfoText = true,
  showDescriptionText = true,
  showDescriptiveText = false,
  showTypeIcon = true,
  showAccessoryIcon = false,
  showExpandHandle = false,
  labelText = "Input Label",
  showInputLabel = false,
  propWidth,
  propHeight,
  propPadding,
  propHeight1,
  propWidth1,
  propTransform,
  value = '',
  onChange,
}) => {
  return (
    <div
      className={`w-[364px] flex flex-col items-start justify-start gap-0.5 min-w-[256px] text-left text-3xs text-color-text-gray font-callout-body ${className}`}
    >
      <div className="self-stretch h-3 flex flex-row items-start justify-end gap-0.5 text-color-text-charcoal">
        {showInputLabel && (
          <b className="w-[246px] relative tracking-[-0.02em]">{labelText}</b>
        )}
        {required && (
          <div className="w-2 relative rounded-[50%] bg-color-background-danger h-2" />
        )}
      </div>
      <div className="self-stretch rounded-6xs bg-color-background-white border-color-text-light-gray border-[1px] border-solid flex flex-row items-center justify-start p-2.5 relative gap-2.5 text-base">
        {showTypeIcon && (
          <Icon
            propWidth={propWidth}
            propHeight={propHeight}
            propPadding={propPadding}
            vector="/icon-shape.svg"
            propHeight1={propHeight1}
            propWidth1={propWidth1}
            propTransform={propTransform}
          />
        )}
        <input
          type="email"
          value={value}
          onChange={onChange}
          placeholder={placeholderText}
          className="flex-1 relative tracking-[-0.02em] font-medium z-[1] bg-transparent border-none outline-none placeholder:text-color-text-gray"
          required={required}
        />
        {showAccessoryIcon && (
          <div className="w-3.5 h-2 flex flex-row items-center justify-center z-[2]">
            <img className="h-2 w-3.5 relative" alt="" />
          </div>
        )}
        {showExpandHandle && (
          <img
            className="w-[7.5px] absolute !m-[0] right-[4px] bottom-[4px] h-[7.5px] z-[3]"
            alt=""
          />
        )}
      </div>
      {showDescriptiveText && (
        <div className="w-64 flex flex-row items-start justify-between">
          {showDescriptionText && (
            <div className="h-3 w-[74px] relative tracking-[-0.02em] font-medium flex items-center">
              {description}
            </div>
          )}
          {showErrorInfoText && (
            <div className="h-3 relative tracking-[-0.02em] font-medium text-right flex items-center text-color-background-danger">
              {errorInfo}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Input1.propTypes = {
  className: PropTypes.string,
  placeholderText: PropTypes.string,
  errorInfo: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.string,
  showErrorInfoText: PropTypes.bool,
  showDescriptionText: PropTypes.bool,
  showDescriptiveText: PropTypes.bool,
  showTypeIcon: PropTypes.bool,
  showAccessoryIcon: PropTypes.bool,
  showExpandHandle: PropTypes.bool,
  labelText: PropTypes.string,
  showInputLabel: PropTypes.bool,
  propWidth: PropTypes.string,
  propHeight: PropTypes.string,
  propPadding: PropTypes.string,
  propHeight1: PropTypes.string,
  propWidth1: PropTypes.string,
  propTransform: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Input1;