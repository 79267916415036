// pages/Orders.jsx
import React, { useState, useEffect } from 'react';
import UserSidebar from "../../components/sidebar/UserSidebar";
import Navbar from "../../components/navbar/Navbar";
import TrackingTable from '../../components/table/Table';
import { useAuth } from '../../components/AuthProvider';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [orders, setOrders] = useState([]); // Original orders
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');
  const { getUser } = useAuth();
  const userData = getUser();

  const fetchOrders = async () => {
    if (!userData?.id) return;

    try {
      setError(null);
      setLoading(true);
      const response = await api.get(`/users/${userData.id}/orders/pending`);
      setOrders(response.data);
      setFilteredOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setError(error.response?.data?.message || 'Failed to fetch orders');
      setOrders([]);
      setFilteredOrders([]);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchOrders();
  }, [userData?.id]);

  // Apply filters
  useEffect(() => {
    let result = [...orders];

    // Apply status filter
    if (statusFilter !== 'all') {
      result = result.filter(order => order.status === statusFilter);
    }

    // Apply date filter
    if (dateFilter !== 'all') {
      const now = new Date();
      let filterDate = new Date();

      switch(dateFilter) {
        case 'today':
          filterDate.setHours(0, 0, 0, 0);
          break;
        case 'last7days':
          filterDate.setDate(filterDate.getDate() - 7);
          break;
        case 'last30days':
          filterDate.setDate(filterDate.getDate() - 30);
          break;
        case 'last12months':
          filterDate.setMonth(filterDate.getMonth() - 12);
          break;
        default:
          break;
      }

      result = result.filter(order => new Date(order.createdAt) >= filterDate);
    }

    setFilteredOrders(result);
  }, [statusFilter, dateFilter, orders]);

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleDateChange = (e) => {
    setDateFilter(e.target.value);
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <UserSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Orders ✨</h1>
              </div>

              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button 
                  className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                  onClick={fetchOrders}
                  disabled={loading}
                >
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                  </svg>
                  <span className="hidden xs:block ml-2">Refresh</span>
                </button>

                <select 
                  className="form-select border-gray-300 dark:border-slate-700 rounded-md text-sm"
                  value={statusFilter}
                  onChange={handleStatusChange}
                  disabled={loading}
                >
                  <option value="all">All Orders</option>
                  <option value="processing">Processing</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                </select>

                <select 
                  className="form-select border-gray-300 dark:border-slate-700 rounded-md text-sm"
                  value={dateFilter}
                  onChange={handleDateChange}
                  disabled={loading}
                >
                  <option value="all">All Time</option>
                  <option value="today">Today</option>
                  <option value="last7days">Last 7 Days</option>
                  <option value="last30days">Last 30 Days</option>
                  <option value="last12months">Last 12 Months</option>
                </select>

                <button 
                  className="btn bg-blue-500 hover:bg-blue-600 text-white"
                  onClick={() => navigate('/tracking')}
                  disabled={loading}
                >
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Track Order</span>
                </button>
              </div>
            </div>

            {/* Orders Table Section */}
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
              <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
                <h2 className="font-semibold text-slate-800 dark:text-slate-100">
                  All Orders{' '}
                  <span className="text-slate-400 dark:text-slate-500 font-medium">
                    {loading ? '...' : filteredOrders.length}
                  </span>
                </h2>
              </header>
              
              {loading ? (
                <div className="p-4 text-center">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-slate-200 border-t-blue-500"></div>
                  <p className="mt-2 text-slate-500 dark:text-slate-400">Loading orders...</p>
                </div>
              ) : error ? (
                <div className="p-4 text-center text-red-500 dark:text-red-400">
                  <p>{error}</p>
                  <button 
                    onClick={fetchOrders}
                    className="mt-2 text-sm text-blue-500 hover:text-blue-600 dark:text-blue-400"
                  >
                    Try again
                  </button>
                </div>
              ) : filteredOrders.length === 0 ? (
                <div className="p-4 text-center text-slate-500 dark:text-slate-400">
                  No orders found. Try adjusting your filters.
                </div>
              ) : (
                <TrackingTable 
                  orders={filteredOrders}
                  onNavigate={navigate}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Orders;