import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import OrdersList from './OrdersList';
import Sidebar from '../sidebar/Sidebar'; // Adjust the path as necessary
import Navbar from '../navbar/Navbar';  

const OrdersTable = () => {
  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={2}>
        <Navbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          
            
            
              <OrdersList />
        </Container>
      </Box>
    </Box>
  );
};

export default OrdersTable;