import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import CreatePickupRequestForm from './PickupRequestForm';
import PickupRequestList from './PickupRequestList';
import Sidebar from '../sidebar/Sidebar'; // Adjust the path as necessary
import Navbar from '../navbar/Navbar';  

const RequestPickup = () => {
  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={2}>
        <Navbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
            </Typography>
            
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom>
              </Typography>
              <PickupRequestList />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom marginBottom={4}>
                Create Pickup Request
              </Typography>
              <CreatePickupRequestForm />
            </Box>
            
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default RequestPickup;