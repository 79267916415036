import Card from "../Card";
import PropTypes from "prop-types";

const Integration = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-col items-center justify-center py-8 px-4 sm:px-6 md:px-8 box-border max-w-full text-left text-2xl sm:text-3xl md:text-4xl lg:text-13xl text-color-background-white font-callout-body ${className}`}
    >
      <div className="w-full max-w-[1551px] flex flex-col sm:flex-row flex-wrap items-center sm:items-stretch justify-center gap-6 sm:gap-4 md:gap-6 lg:gap-8 z-[3]">
        <Card
          vector="/vector-5.svg"
          standardPaymentMethods="Standard payment methods"
          ourPlatformAllowsSeamless="Our platform allows seamless integration into your favourite online shopping experiences without introducing additional checkout steps for you."
          className="w-full sm:w-[calc(50%-8px)] lg:w-[calc(33.333%-16px)]"
        />
        <Card
          vector="/vector-5.svg"
          standardPaymentMethods="Trusted partners"
          ourPlatformAllowsSeamless="With our list of trusted partners, your payments are secured and protected from end-to-end, ensuring you get your goods, and the stores get their money."
          propBackgroundColor="#7cb3ee"
          className="w-full sm:w-[calc(50%-8px)] lg:w-[calc(33.333%-16px)]"
        />
        <Card
          vector="/vector-5.svg"
          standardPaymentMethods="Low commitment usage"
          ourPlatformAllowsSeamless="You don't need a 3 Shepherd account to secure your payment. Just secure your payment with 3 Shepherd and rest easy knowing your funds are safe!"
          propBackgroundColor="#08c989"
          className="w-full sm:w-[calc(50%-8px)] lg:w-[calc(33.333%-16px)]"
        />
      </div>
    </section>
  );
};

Integration.propTypes = {
  className: PropTypes.string,
};

export default Integration;