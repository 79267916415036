import PlaceholderComponentIcon1 from "./PlaceholderComponentIcon1";
import PlaceholderComponentIcon from "./PlaceholderComponentIcon";
import PropTypes from "prop-types";

const FrameComponent3 = ({ className = "", onSeeHowItWorksClick }) => {
  return (
    <div
      className={`w-full max-w-[1788px] flex flex-row items-start justify-between py-0 pr-0 pl-5 box-border gap-[20px] text-left text-45xl text-color-text-charcoal font-callout-body
        mq1575:flex-wrap mq825:px-4 ${className}`}
    >
      <div className="w-[596px] flex flex-col items-start justify-start pt-[31px] px-0 pb-0 box-border max-w-full 
        mq825:w-full mq825:pt-4">
        <div className="self-stretch flex flex-col items-start justify-start gap-[28px] mq825:gap-[20px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[15px]">
            <div className="self-stretch relative min-h-[154px] mq825:min-h-0">
              {/* Desktop highlight */}
              <div className="absolute top-[50px] left-[127px] bg-color-secondary-soft-jade w-[232px] h-[22px] z-[1]
                mq825:hidden" />
              
              {/* Combined heading for desktop and mobile */}
              <h1 className="m-0 relative text-inherit tracking-[-0.02em] font-bold font-inherit text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_28.63%,_rgba(255,_255,_255,_0)_48.44%,_rgba(255,_255,_255,_0.5)_51.04%,_rgba(255,_255,_255,_0)_79.69%),_#272329] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex items-center [text-shadow:0px_4px_4px_rgba(0,_0,_0,_0.25)] z-[2]
                desktop:ml-[100px] mq825:ml-0 mq825:text-4xl">
                {/* Desktop view */}
                <span className="w-full desktop:block mq825:hidden">
                  <p className="m-0">Feel secure while</p>
                  <p className="m-0">transacting online</p>
                </span>
                
                {/* Mobile view */}
                <span className="hidden mq825:block w-full">
                  <p className="m-0">Feel Secure</p>
                  <p className="m-0 relative my-1">
                    <span className="relative">
                      While
                      <div className="absolute -z-10 top-0 left-0 w-full h-full bg-color-secondary-soft-jade" />
                    </span>
                  </p>
                  <p className="m-0">transacting</p>
                  <p className="m-0">online</p>
                </span>
              </h1>
            </div>

            <div className="self-stretch relative text-5xl tracking-[-0.02em] font-medium z-[1]
              desktop:ml-[100px] mq825:ml-0 mq825:text-xl">
              Protect your money with 3 Shepherd - the only service that secures
              your payments and goods.
            </div>
          </div>
          <div className="self-stretch relative desktop:ml-[100px] mq825:ml-0 z-[10]">
            <button
              className="cursor-pointer [border:none] py-[15px] px-[30px] bg-color-primary-bright-jade rounded-6xs flex flex-row items-start justify-start whitespace-nowrap hover:bg-mediumseagreen transition-colors duration-300
                mq825:w-full mq825:justify-center"
              onClick={onSeeHowItWorksClick}
            >
              <div className="relative text-5xl tracking-[-0.02em] font-semibold font-callout-body text-color-background-white text-left
                mq825:text-xl">
                See how it works
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Right section - Hidden on mobile */}
      <div className="w-[926px] flex flex-col items-start justify-start gap-[4px] max-w-full
        mq1250:min-w-0 mq1575:flex-1 mq825:hidden">
        <div className="self-stretch flex flex-col items-end justify-start gap-[1px] max-w-full">
          <div className="w-[725px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <div className="h-[71px] w-[497px] relative max-w-full">
              <img
                className="absolute top-[13px] left-[0px] w-[497px] h-[45px] max-w-full"
                loading="lazy"
                alt=""
                src="/correct-segment.svg"
              />
              <div className="absolute h-full top-[0px] bottom-[0px] left-[323px] rounded-[50%] box-border w-[71px] z-[1] border-[0px] border-solid border-color-background-soft-sky" />
            </div>
            <PlaceholderComponentIcon1 />
          </div>
          <div className="w-[886px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <PlaceholderComponentIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
  onSeeHowItWorksClick: PropTypes.func,
};

export default FrameComponent3;