import React, { useState, useEffect } from 'react';
import { Bell, DollarSign, Package, AlertCircle, Truck, ClipboardList } from 'lucide-react';
import api from '../../services/api';
import { useAuth } from '../../components/AuthProvider';
import { formatDistanceToNow } from 'date-fns';

const MerchantNotifications = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getUser } = useAuth();
  const userData = getUser();
  const merchantId = userData?.id;

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await api.get(`/activities/${merchantId}`);
        if (response.data.success) {
          setActivities(response.data.data);
        } else {
          setError('Failed to load activities');
        }
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError('Failed to load recent activities');
      } finally {
        setLoading(false);
      }
    };

    if (merchantId) {
      fetchActivities();
    }
  }, [merchantId]);

  const getActivityIcon = (type) => {
    switch (type) {
      case 'ORDER':
        return <Package className="w-6 h-6 text-white" />;
      case 'SHIPMENT':
        return <Truck className="w-6 h-6 text-white" />;
      case 'TRANSACTION':
        return <DollarSign className="w-6 h-6 text-white" />;
      case 'PICKUP':
        return <ClipboardList className="w-6 h-6 text-white" />;
      default:
        return <Bell className="w-6 h-6 text-white" />;
    }
  };

  const getStatusBadgeColor = (type, status) => {
    const statusKey = status?.toUpperCase();
    switch (statusKey) {
      case 'NEW':
      case 'CREATED':
        return 'bg-emerald-100 text-emerald-600 dark:bg-emerald-400/30 dark:text-emerald-400';
      case 'PENDING':
      case 'IN_PROGRESS':
      case 'PICKED_UP':
        return 'bg-amber-100 text-amber-600 dark:bg-amber-400/30 dark:text-amber-400';
      case 'COMPLETED':
      case 'DELIVERED':
      case 'PAID':
        return 'bg-blue-100 text-blue-600 dark:bg-blue-400/30 dark:text-blue-400';
      case 'CANCELLED':
      case 'FAILED':
        return 'bg-red-100 text-red-600 dark:bg-red-400/30 dark:text-red-400';
      default:
        return 'bg-slate-100 text-slate-600 dark:bg-slate-400/30 dark:text-slate-400';
    }
  };

  const getActivityBgColor = (type) => {
    switch (type) {
      case 'ORDER':
        return 'bg-blue-500';
      case 'SHIPMENT':
        return 'bg-purple-500';
      case 'TRANSACTION':
        return 'bg-emerald-500';
      case 'PICKUP':
        return 'bg-amber-500';
      default:
        return 'bg-slate-500';
    }
  };

  return (
    <div className="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">Recent Activity</h2>
      </header>

      <div className="p-3">
        {loading ? (
          <div className="flex justify-center items-center p-4">
            <span className="text-slate-500 dark:text-slate-400">Loading...</span>
          </div>
        ) : error ? (
          <div className="flex justify-center items-center p-4 text-red-500">
            {error}
          </div>
        ) : activities.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <AlertCircle className="w-9 h-9 text-slate-400 mb-3" />
            <p className="text-slate-500 dark:text-slate-400">No recent activity</p>
          </div>
        ) : (
          <div>
            <ul className="my-1">
              {activities.map((activity, index) => (
                <li key={index} className="flex px-2">
                  <div className={`w-9 h-9 rounded-full shrink-0 my-2 mr-3 flex items-center justify-center ${getActivityBgColor(activity.type)}`}>
                    {getActivityIcon(activity.type)}
                  </div>

                  <div className="grow flex items-center border-b border-slate-100 dark:border-slate-700 text-sm py-2">
                    <div className="grow flex justify-between">
                      <div className="self-center">
                        <span className="font-medium text-slate-800 dark:text-slate-100">
                          {activity.message}
                        </span>
                      </div>
                      <div className="shrink-0 self-end ml-2">
                        <span className={`inline-flex font-medium rounded-full text-xs px-2 py-0.5 ${getStatusBadgeColor(activity.type, activity.status)}`}>
                          {activity.status}
                        </span>
                        <div className="text-xs text-slate-400 dark:text-slate-500 mt-1">
                          {formatDistanceToNow(new Date(activity.timestamp), { addSuffix: true })}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MerchantNotifications;