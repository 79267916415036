import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  maxBodyLength: 100000, // Increase if needed
  maxContentLength: 100000 // Increase if needed
});

api.interceptors.request.use((config) => {
  // Change from localStorage to sessionStorage
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});



export default api;