import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import UserList from '../components/Merchant/UserList';
import MerchantList from '../components/Merchant/MerchantList';
import Sidebar from '../components/sidebar/Sidebar'; // Adjust the path as necessary
import Navbar from '../components/navbar/Navbar';  

const Admin = () => {
  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={2}>
        <Navbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Welcome!
            </Typography>
            
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom>
                Users
              </Typography>
              <UserList />
            </Box>
            
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom>
                Merchants
              </Typography>
              <MerchantList />
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default Admin;

