import React, { useEffect, useState } from "react";
import { Card, List, ListItem, Title, Text, Bold, Flex } from "@tremor/react";
import { UserIcon } from "@heroicons/react/solid";
import api from '../../../services/api';

const getRandomColor = () => {
  const colors = [
    "bg-red-500", "bg-blue-500", "bg-green-500", "bg-yellow-500",
    "bg-blue-500", "bg-purple-500", "bg-pink-500"
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const TopMerchants = () => {
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopMerchants = async () => {
      try {
        setLoading(true);
        const response = await api.get('/business/top');
        
        if (response.data.success) {
          setMerchants(response.data.data || []);
        } else {
          throw new Error(response.data.message || 'Failed to fetch merchants');
        }
      } catch (error) {
        console.error('Error fetching top merchants:', error);
        setError(error.message || 'Error loading merchant data');
      } finally {
        setLoading(false);
      }
    };

    fetchTopMerchants();
  }, []);

  if (loading) {
    return (
      <Card className="max-w-full">
        <Title>High Performing Merchants</Title>
        <Text className="mt-4">Loading...</Text>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="max-w-full">
        <Title>High Performing Merchants</Title>
        <Text className="mt-4 text-red-500">{error}</Text>
      </Card>
    );
  }

  if (!merchants || merchants.length === 0) {
    return (
      <Card className="max-w-full">
        <Title>High Performing Merchants</Title>
        <Text className="mt-4">No merchant data available</Text>
      </Card>
    );
  }

  return (
    <Card className="max-w-full">
      <Title>High Performing Merchants</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Business</Bold>
        </Text>
        <Text>
          <Bold>Orders Received</Bold>
        </Text>
      </Flex>
      <List>
        {merchants.map((merchant, index) => (
          <ListItem 
            key={merchant.merchant_id || index} 
            className="flex justify-between items-center"
          >
            <div className="flex items-center">
              <div 
                className={`w-6 h-6 rounded-full ${getRandomColor()} mr-2 flex justify-center items-center`}
              >
                <UserIcon width={12} className="text-white" />
              </div>
              <Text>{merchant.business_name || 'Unknown Business'}</Text>
            </div>
            <Text>{merchant.order_count || 0}</Text>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default TopMerchants;