import React, { useRef, useEffect, useState } from 'react';
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { useAuth } from '../AuthProvider';
import api from '../../services/api';

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend
);

const ModernChart = ({ title }) => {
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const canvasRef = useRef(null);
  const { getUser } = useAuth();
  const userData = getUser();
  const merchantId = userData?.id;

  useEffect(() => {
    let chartInstance = null;

    const initChart = (chartData) => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartData.labels,
          datasets: [{
            label: 'Orders',
            data: chartData.data,
            backgroundColor: '#3b82f6',
            barPercentage: 0.5,
            categoryPercentage: 0.8,
          }]
        },
        options: {
          layout: {
            padding: {
              top: 20,
              bottom: 0,
              left: 20,
              right: 20,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: '#94a3b8',
                font: {
                  size: 12,
                }
              },
              border: {
                display: false,
              }
            },
            y: {
              position: 'left',
              border: {
                display: false,
              },
              grid: {
                color: '#e2e8f0',
                drawBorder: false,
              },
              ticks: {
                color: '#94a3b8',
                font: {
                  size: 12,
                },
                maxTicksLimit: 6,
                callback: (value) => value
              },
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
              align: 'start',
              labels: {
                color: '#64748b',
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 20,
                boxWidth: 8,
                boxHeight: 8,
                font: {
                  size: 12,
                }
              }
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              backgroundColor: '#ffffff',
              titleColor: '#1e293b',
              bodyColor: '#1e293b',
              borderColor: '#e2e8f0',
              borderWidth: 1,
              padding: 12,
              cornerRadius: 4,
              callbacks: {
                label: (context) => {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
        }
      });

      setChart(chartInstance);
    };

    const fetchData = async () => {
      if (!merchantId) return;

      try {
        const response = await api.get(`/business/monthly-orders/${merchantId}`);
        console.log('API Response:', response.data);

        const chartData = {
          labels: response.data.formattedResult.map(item => item.name),
          data: response.data.formattedResult.map(item => item.Orders)
        };

        initChart(chartData);
        setError(null);
      } catch (err) {
        console.error('Error fetching revenue data:', err);
        setError(err.message || 'Failed to load chart data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [merchantId]);

  return (
    <div className="flex flex-col col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">{title}</h2>
      </header>
      
      <div className="flex-1 px-5 py-6">
        {loading ? (
          <div className="animate-pulse flex flex-col h-[300px]">
            <div className="bg-slate-200 dark:bg-slate-700 h-full rounded"></div>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-[300px] text-slate-600 dark:text-slate-400">
            <p>Error loading chart data: {error}</p>
          </div>
        ) : (
          <div className="h-[300px] w-full relative">
            <canvas ref={canvasRef} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModernChart;