import React, { useState, useEffect } from "react";
import {
  BadgeDelta,
  ProgressCircle,
  Button,
  Card,
  Flex,
  TabGroup,
  Tab,
  TabList,
  Bold,
  Divider,
  List,
  ListItem,
  Metric,
  Text,
  Title,
} from "@tremor/react";
import {
  ArrowRightIcon,
  ChartPieIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import api from '../../../services/api';

const dataFormatter = (number) => {
  return "$ " + Intl.NumberFormat("us").format(number).toString();
};

const SalesItem = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [orderData, setOrderData] = useState({ totalOrders: 0, deliveredOrders: 0 });

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await api.get('/orders/order-counts');
        setOrderData(response.data);
      } catch (error) {
        console.error('Error fetching order counts:', error);
      }
    };

    fetchOrderData();
  }, []);

  const { totalOrders, deliveredOrders } = orderData;
  const progressValue = (deliveredOrders / totalOrders) * 100;
  const openOrders = totalOrders-deliveredOrders;

  return (
    <Card className="max-w-full mx-auto">
      <Flex className="space-x-8 flex-col lg:flex-row">
        <Title>Overview</Title>
        <TabGroup index={selectedIndex} onIndexChange={setSelectedIndex}>
          <TabList variant="solid">
            <Tab icon={ChartPieIcon}>Chart</Tab>
            <Tab icon={ViewListIcon}>List</Tab>
          </TabList>
        </TabGroup>
      </Flex>
      <Text className="mt-8">Open Orders</Text>
      <Metric>Total pending orders: {openOrders}</Metric>
      <Divider />
      <Text className="mt-8">
        <Bold>Order Fulfillment</Bold>
      </Text>
      <Text>Total Orders vs Delivered Orders</Text>
      {selectedIndex === 0 ? (
        <ProgressCircle value={progressValue} size="xl" color = 'green'>
        <span className="text-xs font-medium text-slate-700">
          {Math.round(progressValue)}%
        </span>
      </ProgressCircle>
      ) : (
        <>
          <Flex className="mt-8" justifyContent="between">
            <Text className="truncate">
              <Bold>Orders</Bold>
            </Text>
            <Text>Details</Text>
          </Flex>
          <List className="mt-4">
            <ListItem>
              <Text>Total Orders</Text>
              <Flex className="space-x-2" justifyContent="end">
                <Text>{totalOrders}</Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Text>Delivered Orders</Text>
              <Flex className="space-x-2" justifyContent="end">
                <Text>{deliveredOrders}</Text>
              </Flex>
            </ListItem>
          </List>
        </>
      )}
      <Flex className="mt-6 pt-4 border-t">
        <Button
          size="xs"
          variant="light"
          icon={ArrowRightIcon}
          iconPosition="right"
        >
          View more
        </Button>
      </Flex>
    </Card>
  );
};

export default SalesItem;