import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ children, allowedRoles = [] }) => {
  const location = useLocation();
  const { isAuthenticated, getUser, isInitialized, isLoading } = useAuth();

  // Show loading while initializing auth
  if (!isInitialized) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <CircularProgress />
      </div>
    );
  }

  // After initialization, check if authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Get user data from token
  const userData = getUser();
  
  // Check role access if roles are specified
  if (allowedRoles.length > 0) {
    const userRole = userData?.role?.toLowerCase();
    if (!userRole || !allowedRoles.includes(userRole)) {
      switch(userRole) {
        case 'user':
          return <Navigate to="/userpanel" replace />;
        case 'merchant':
        case 'owner':
          return <Navigate to="/panel" replace />;
        case 'shipping':
          return <Navigate to="/panel" replace />;
        case 'finance':
          return <Navigate to="/panel" replace />;
        default:
          return <Navigate to="/login" replace />;
      }
    }
  }

  return children;
};

export default PrivateRoute;