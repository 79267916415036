import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import Review from './Review';
import PaymentForm1 from './PaymentForm1';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        3-Shepherd Tech Limited.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  position: 'relative',
}));

const logoURL = 'https://i.postimg.cc/2yvkxf7G/color-logo-no-background-1-2x.png';
const payslogo = 'https://i.postimg.cc/cJxM58KC/pay-with-3s-icon-logo-2x.png';

const Layout = styled('main')(({ theme }) => ({
  width: '600px',
  marginLeft: theme.spacing(90),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    width: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

const StepperStyled = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(3, 0, 5),
}));

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const ButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(1),
}));

const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '20px',
});

const steps = ['Shipping address', 'Review your order', 'Payment details'];

function getStepContent(step, formData, setFormData, handleSubmit, shippingRate, transitTime, setShippingRate, setTransitTime, totalWithShipping, clientSecret, setClientSecret, setPaymentStatus, selectedService, setSelectedService) {
  switch (step) {
    case 0:
      return <AddressForm formData={formData} setFormData={setFormData} />;
    case 1:
      return (
        <Review
          formData={formData}
          shippingRate={shippingRate}
          transitTime={transitTime}
          setShippingRate={setShippingRate}
          setTransitTime={setTransitTime}
          setClientSecret={setClientSecret}
          selectedService={selectedService}
          setSelectedService={setSelectedService} // Pass the setter function
        />
      );
    case 2:
      return <PaymentForm1 formData={formData} clientSecret={clientSecret} handleSubmit={handleSubmit} totalAmount={totalWithShipping} setPaymentStatus={setPaymentStatus} selectedService={selectedService}/>;
    default:
      throw new Error('Unknown step');
  }
}
const user = JSON.parse(localStorage.getItem('user'));
export default function Checkout() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    user_id: '', // 
    merchant_id: 47, // Initialize with a default merchant_id
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });
  const [shippingRate, setShippingRate] = useState(null);
  const [transitTime, setTransitTime] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(false); // New state for payment status
  const [selectedService, setSelectedService] = useState(''); // New state for selected shipping service

  const totalPrice = 34.06; // Sum of product prices
  const totalWithShipping = totalPrice + (shippingRate || 0);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBarStyled color="default">
        
      </AppBarStyled>
      <Layout>
        <PaperStyled>
          <HeaderContainer>
            <img src={payslogo} alt="3-Shepherd Logo" style={{ height: '100px', marginRight: '-10px' }} />
            <Typography component="h1" variant="h4" align="center">
              Pay with 3S
            </Typography>
          </HeaderContainer>
          <StepperStyled activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </StepperStyled>
          <React.Fragment>
            {getStepContent(activeStep, formData, setFormData, handleSubmit, shippingRate, transitTime, setShippingRate, setTransitTime, totalWithShipping, clientSecret, setClientSecret, setPaymentStatus, selectedService, setSelectedService)}
            <Buttons >
              {activeStep !== 0 && (
                <ButtonStyled onClick={handleBack} variant="outlined">
                  Back
                </ButtonStyled>
              )}
              {activeStep === steps.length - 1 && paymentStatus && (
                <ButtonStyled
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Place order
                </ButtonStyled>
              )}
              {activeStep !== steps.length - 1 && (
                <ButtonStyled
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </ButtonStyled>
              )}
            </Buttons>
          </React.Fragment>
        </PaperStyled>
        <Copyright />
      </Layout>
    </React.Fragment>
  );
}
