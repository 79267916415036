import React, { useRef }  from 'react';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TeamMember = ({ imageSrc, name, role, description }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} className="flex flex-col md:flex-row items-center md:items-start justify-start gap-6 md:gap-8 max-w-[1000px] mb-8 md:mb-10 px-4">
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={inView ? { x: 0, opacity: 1 } : { x: -50, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center justify-start"
      >
        <img
          className="w-[200px] h-[200px] md:w-[290px] md:h-[290px] rounded-full object-cover mb-4"
          alt={name}
          src={imageSrc}
        />
        <b className="text-xl md:text-2xl tracking-[-0.02em] text-transparent bg-clip-text bg-gradient-to-b from-[rgba(255,255,255,0.5)] to-[rgba(35,38,41,1)] mb-2 text-center">
          {name}
        </b>
        <div className="text-sm md:text-base tracking-[-0.02em] font-light opacity-30 text-center">
          {role}
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 30 }}
        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 30 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex-1 text-sm md:text-base tracking-[-0.01em] font-sm text-center md:text-left md:self-start md:mt-[75px]"
      >
        {description}
      </motion.div>
    </div>
  );
};

const AboutUs = () => {
  const teamSectionRef = useRef(null);

  const scrollToTeam = () => {
    teamSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative w-full bg-color-background-white overflow-x-hidden min-h-screen">
      {/* Hero Section */}
      <div className="w-full h-[60vh] md:h-[1188px] relative">
        <img
          className="absolute h-full w-full object-cover"
          alt=""
          src="/saschabosshardgfmeeliy7r8unsplash-1@2x.png"
        />
        
        {/* Header Content */}
        <div className="relative z-10 pt-16 px-4 md:px-8 lg:px-[350px] text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">About us</h1>
          <div className="flex space-x-4">
            <button className="text-sm bg-transparent text-white px-3 py-1 hover:underline transition-all">
              Our Mission
            </button>
            <button 
              className="text-sm bg-transparent text-white px-3 py-1 hover:underline transition-all"
              onClick={scrollToTeam}
            >
              The Team
            </button>
          </div>
        </div>

        {/* Mission Statement */}
        <div className="absolute top-[300px] w-full px-4 md:px-8 lg:px-[300px] z-10">
          <div className="bg-white/20 backdrop-blur-md p-6 rounded-lg text-white text-left max-w-4xl">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Our mission</h2>
            <p className="text-base md:text-lg mb-2">
              We are driven by a passion for making online transactions safer and easier to use.
            </p>
            <p className="text-sm">
              Based in Dubai, UAE, 3 Shepherd aims to change the world of online commerce.
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="w-full px-4 md:px-8 lg:px-[300px] py-12 md:py-16">
        {/* Market Stats Section */}
        <div className="mb-16 text-base md:text-xl">
          <p className="mb-4">
            <span className="font-medium">The global e-commerce industry is booming, set to reach a market value of </span>
            <b>US$5.56 trillion by 2027</b>.
          </p>
          <p className="text-sm md:text-base">
            However, a major challenge it faces is the rise in fraud and financial crimes, which erode trust among consumers. 
            In 2020, online scams made up <b>38% of reported fraud cases worldwide</b>, increasing from 24% pre-pandemic.
          </p>
        </div>

        {/* Solution Section */}
        <div className="mb-16 text-left">
          <p className="text-xl md:text-2xl mb-4">
            <span className="font-medium">At 3-Shepherd, we're dedicated to </span>
            <b>tackling these challenges head-on</b>.
          </p>
          <p className="text-sm md:text-base">
            We understand that people hesitate to engage in e-commerce due to the perceived risks and high costs of 
            traditional banking services. That's why we've developed a cutting-edge Transaction Lifecycle Management (TLM) solution.
          </p>
        </div>

        {/* TLM Features */}
        <div className="mb-16 text-left">
          <h3 className="text-2xl md:text-3xl font-bold mb-6">Our TLM solution focuses on:</h3>
          <ol className="list-decimal pl-6 space-y-4 text-sm md:text-base">
            <li>Enhanced Transparency: Providing clear visibility into transactions for all parties involved.</li>
            <li>Empowerment: Equipping stakeholders with tools to manage fraud and financial risks effectively.</li>
            <li>Seamless Integration: Creating a smooth process that enhances the customer experience and secures transactions.</li>
          </ol>
        </div>

        {/* Mission Statement */}
        <div className="text-left mb-24">
          <p className="text-2xl md:text-3xl font-bold">
            Our ultimate goal is to restore trust and confidence in the e-commerce industry.
          </p>
        </div>
      </div>

      {/* Team Section */}
      <div ref={teamSectionRef} className="w-full px-4 md:px-8 py-12 md:py-16">
    <h2 className="text-4xl md:text-6xl font-bold text-center mb-12 tracking-[-0.02em] text-transparent bg-clip-text bg-gradient-to-b from-[rgba(255,255,255,0.5)] to-[rgba(35,38,41,1)]">
      The team
    </h2>
    <div className="max-w-[1000px] mx-auto">
      <TeamMember
        imageSrc="/image 3.png"
        name="Hassan Tajudin"
        role="CEO"
        description="Hassan is a seasoned treasury professional with over 15 years of banking experience in diverse markets, including roles at Dubai Islamic Bank and Standard Chartered Bank. He holds a BBAMIS degree and an MSc in Finance, with a thesis focusing on Microscopic Simulation. Hassan has led teams in developing innovative technology-driven financial solutions, including serving as team lead for HBL's FX trading platform, HBL Infinity. He's also skilled in technology, with achievements in the Microsoft Imagine Cup and holding the title of Certified Meta Developer."
      />
      <TeamMember
        imageSrc="/image 6.png"
        name="Pieter Vos"
        role="Strategy Advisor | Board Member"
        description="Pieter is a commercially focused business leader with more than 15 years of experience in the financial sector and has lead teams across more than 25 countries. He holds a B.Sc in Digital Technology and Design from the Technological University of Dublin; and an Masters in Business Administration from the University of Illinois with a major in Mergers & Acquisitions and Entrepreneurship & Strategic Innovation."
      />
      <TeamMember
        imageSrc="/image 7.png"
        name="Janico Steyn"
        role="UI/UX Designer"
        description="Janico is a Google Certified User Interface and User Experience (UI & UX) design and research professional with a Diploma in Graphic Design from the University of Cape Town and 12 years of experience. His experience in the creative and digital arts landscape over the past dozen years include Graphic Design & Sketch Artistry, Videography & Editing, Music Production and more."
      />
    </div>
  </div>

      {/* Footer/Social Section */}
      <div className="w-full px-4 md:px-8 lg:px-[152px] py-12 md:py-16">
        <b className="text-2xl md:text-3xl mb-6 block tracking-[-0.02em] text-color-text-button-black">
          Connect with us on social media
        </b>
        <div className="flex gap-4">
          <img className="w-6 h-6" alt="" src="/linekdin-icon.svg" />
          <img className="w-6 h-6" alt="" src="/x-icon.svg" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;