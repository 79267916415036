import PropTypes from "prop-types";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

const FrameComponent2 = ({ className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"]
  });

  const y = useTransform(scrollYProgress, [0, 1], ["0%", "-20%"]);

  return (
    <section
      ref={ref}
      className={`w-full h-[974.1px] absolute !m-[0] top-[422px] right-[0px] left-[0px] ${className}`}
    >
      <div className="relative top-[-60px] left-[-336px] w-[2351.9px] h-[968.7px] flex flex-row items-start justify-start">
        <img
          className="h-[968.7px] flex-1 relative max-w-full overflow-hidden object-contain"
          alt=""
          src="/background-waves@2x.png"
        />
        <motion.div 
          style={{ y }}
          className="h-[1587.5px] w-[652.7px] !m-[0] absolute right-[500.2px] bottom-[-500.8px] flex flex-row items-start justify-start py-[263px] px-0 box-border max-w-full z-[2]"
        >
          <div className="h-[1024.5px] flex-1 relative max-w-full">
            <img
              className="relative top-[350px] left-[0px] w-full h-full object-contain"
              alt=""
              src="/phone mockup.png"
            />
            <img
              className="absolute top-[143px] left-[183px] w-[170.6px] h-[82.6px] object-contain z-[1]"
              loading="lazy"
              alt=""
              src="/vector-108.svg"
            />
          </div>
        </motion.div>
      </div>
      {/*<img
        className="absolute top-[350px] left-[334px] rounded-[32.25px] w-[154.1px] h-[154.1px] object-contain z-[1]"
        loading="lazy"
        alt=""
        src="/pay-with-3s-icon-logo@2x.png"
      /> */}
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;