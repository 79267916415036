import React, { useEffect, useState } from "react";
import DisputeDetailsDrawer from "./DisputeDetailsDrawer";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
  Button,
  Flex,
} from "@tremor/react";
import { 
  DocumentDownloadIcon, 
  ExclamationCircleIcon,
  ClockIcon,
  EyeIcon
} from "@heroicons/react/solid";
import api from "../../../services/api";

const DisputeTable = () => {
  const [loading, setLoading] = useState(true);
  const [disputes, setDisputes] = useState([]);
  const [selectedDisputeId, setSelectedDisputeId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    totalPages: 1
  });

  useEffect(() => {
    fetchDisputes();
  }, []);

  const fetchDisputes = async () => {
    try {
      setLoading(true);
      const response = await api.get("/disputes");
      if (response.data && response.data.disputes) {
        setDisputes(response.data.disputes);
        setPagination({
          total: response.data.total,
          currentPage: response.data.currentPage,
          totalPages: response.data.totalPages
        });
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    const statusColors = {
      'needs_response': 'red',
      'under_review': 'yellow',
      'won': 'green',
      'lost': 'gray',
      'warning_closed': 'orange',
      'manual_review': 'purple'
    };
    return statusColors[status] || 'gray';
  };


  const formatCurrency = (amount, currency = 'AED') => {
    return new Intl.NumberFormat('en-AE', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const getDueStatus = (dueDate) => {
    if (!dueDate) return null;
    const now = new Date();
    const due = new Date(dueDate);
    const daysLeft = Math.ceil((due - now) / (1000 * 60 * 60 * 24));
    
    if (daysLeft < 0) return { color: 'red', text: 'Overdue' };
    if (daysLeft <= 2) return { color: 'orange', text: `${daysLeft} days left` };
    return { color: 'green', text: `${daysLeft} days left` };
  };

  const handleViewDetails = (disputeId) => {
    setSelectedDisputeId(disputeId);
    setIsDrawerOpen(true);
  };

  const handleDownloadEvidence = async (disputeId) => {
    try {
      const response = await api.get(`/disputes/${disputeId}/evidence/download`, {
        responseType: 'blob'
      });

      // Check if response is valid
      if (!response.data) {
        throw new Error('No evidence file received');
      }

      // Create blob and download link
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      
      // Get filename from header or use default
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : `evidence-${disputeId}.pdf`;

      // Set up and trigger download
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Error downloading evidence:", error);
      // You might want to show an error message to the user here
      alert('Failed to download evidence. Please try again.');
    }
  };

  if (loading) {
    return <div className="flex justify-center p-4">Loading disputes...</div>;
  }

  return (
    <>
      <Card className="mt-4">
        <Flex className="justify-between">
          <Title>Disputes</Title>
          <Text className="text-gray-500">
            Total Amount: {formatCurrency(disputes.reduce((sum, d) => sum + Number(d.amount), 0))}
          </Text>
        </Flex>
        
        <Table className="mt-5">
          <TableHead>
            <TableRow>
              <TableHeaderCell>Dispute ID</TableHeaderCell>
              <TableHeaderCell>Order ID</TableHeaderCell>
              <TableHeaderCell>Amount</TableHeaderCell>
              <TableHeaderCell>Reason</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Due Date</TableHeaderCell>
              <TableHeaderCell>Evidence Status</TableHeaderCell>
              <TableHeaderCell>Actions</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(disputes) && disputes.length > 0 ? (
              disputes.map((dispute) => {
                const dueStatus = getDueStatus(dispute.evidence_due_by);
                return (
                  <TableRow key={dispute.dispute_id}>
                    <TableCell>
                      <Flex className="items-center gap-2">
                        <Text>{dispute.stripe_dispute_id || dispute.dispute_id}</Text>
                      </Flex>
                    </TableCell>
                    <TableCell>
                      <Text>{dispute.order_id}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{formatCurrency(dispute.amount, dispute.currency)}</Text>
                    </TableCell>
                    <TableCell>
                      <Flex className="items-center gap-2">
                        <ExclamationCircleIcon className="h-5 w-5 text-gray-500" />
                        <Text>{dispute.reason}</Text>
                      </Flex>
                    </TableCell>
                    <TableCell>
                      <Badge color={getStatusColor(dispute.status)}>
                        {dispute.status?.replace(/_/g, ' ').toUpperCase()}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {dueStatus && (
                        <Flex className="items-center gap-2">
                          <ClockIcon className="h-5 w-5" color={dueStatus.color} />
                          <Text color={dueStatus.color}>{dueStatus.text}</Text>
                        </Flex>
                      )}
                    </TableCell>
                    <TableCell>
                      {dispute.evidence_path ? (
                        <Badge color="green">Evidence Available</Badge>
                      ) : (
                        <Badge color="gray">No Evidence</Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <Flex className="gap-2">
                        <Button 
                          size="xs"
                          variant="secondary"
                          icon={EyeIcon}
                          tooltip="View Details"
                          onClick={() => handleViewDetails(dispute.dispute_id)}
                        >
                          View
                        </Button>
                        {dispute.evidence_path && (
                          <Button
                            size="xs"
                            icon={DocumentDownloadIcon}
                            onClick={() => handleDownloadEvidence(dispute.dispute_id, dispute.evidence_path)}
                            tooltip="Download Evidence"
                          >
                            Evidence
                          </Button>
                        )}
                      </Flex>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  No disputes found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        
        <div className="mt-4 flex justify-between items-center">
          <Text>
            Showing {disputes.length} of {pagination.total} disputes
          </Text>
        </div>
      </Card>
  
      <DisputeDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        disputeId={selectedDisputeId}
      />
    </>
  );
}

export default DisputeTable;