import PropTypes from "prop-types";

const PlaceholderComponentIcon1 = ({ className = "" }) => {
  return (
    <img
      className={`self-stretch h-[17px] relative max-w-full overflow-hidden shrink-0 ${className}`}
      loading="lazy"
      alt=""
      src="/component-2.svg"
    />
  );
};

PlaceholderComponentIcon1.propTypes = {
  className: PropTypes.string,
};

export default PlaceholderComponentIcon1;
