import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactForm from "./ContactForm";

const ContactUs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const onMenuItemClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setError("Please enter your name");
      return false;
    }
    if (!formData.email.trim()) {
      setError("Please enter your email");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email");
      return false;
    }
    if (!formData.message.trim()) {
      setError("Please enter your message");
      return false;
    }
    if (!isChecked) {
      setError("Please agree to the terms and conditions");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: 'b164fdc4-dfa7-4608-9ca5-0001c5a97fb3',
          name: formData.name,
          email: formData.email,
          message: formData.message,
          from_name: "3 Shepherd Contact Form",
          subject: "New Contact Form Submission",
        })
      });

      const data = await response.json();
      
      if (data.success) {
        setIsSuccess(true);
        setFormData({ name: "", email: "", message: "" });
        setIsChecked(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      } else {
        throw new Error(data.message || 'Submission failed');
      }
    } catch (err) {
      console.error('Submission error:', err);
      setError("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full relative bg-color-background-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[70px] box-border gap-[51px] leading-[normal] tracking-[normal] text-left text-45xl text-color-text-charcoal font-h4 mq700:gap-[25px]">
      <div className="w-full h-[1132px] absolute !m-[0] right-[0px] bottom-[0px] left-[0px]">
        <img
          className="absolute top-[241px] left-[-309px] w-[2351.9px] h-[968.7px]"
          alt=""
          src="/background-waves.svg"
        />
        <div className="absolute top-[50px] left-[1323px] w-[372px] h-[667.2px] z-[1]">
          <img
            className="absolute top-[54.8px] right-[56.8px] rounded-[28.47px] w-[257.8px] h-[558.3px] object-cover"
            alt=""
            src="/image-6@2x.png"
          />
          <img
            className="absolute top-[0px] right-[0px] w-full h-full object-cover mix-blend-multiply z-[1]"
            alt=""
            src="/image-7@2x.png"
          />
          <div className="absolute top-[94px] left-[256px] w-[41px] h-[41px]">
            <div className="absolute top-[1px] left-[1px] rounded-[50%] bg-color-background-soft-sky w-[39px] h-[39px] z-[2]" />
            <img
              className="absolute top-[0px] left-[0px] w-full h-full z-[3]"
              loading="lazy"
              alt=""
              src="/vector.svg"
            />
          </div>
        </div>
      </div>
      
      <div className="w-[1829px] flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[62px] max-w-full mq450:gap-[15px] mq700:gap-[31px]">
        <div className="w-[898px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
          <div className="w-[596px] flex flex-col items-start justify-start max-w-full">
            <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full">
              <h1 className="m-0 flex-1 relative text-inherit tracking-[-0.02em] font-bold font-inherit text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block max-w-full mq450:text-[38px] mq900:text-[51px]">
                Contact us
              </h1>
            </div>
            <h3 className="m-0 relative text-5xl tracking-[-0.02em] font-medium font-inherit mq450:text-lgi">
              <p className="m-0">Let's start a conversation!</p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">
                We would love to connect with you to answer all your questions
                and provide all the information you need.
              </p>
            </h3>
          </div>
        </div>
        <div className="w-[1054px] flex flex-col items-start justify-start pt-0 px-0 pb-2.5 box-border gap-[33px] max-w-full text-3xs mq700:gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-end max-w-full">
            <div className="w-[922px] flex flex-col items-start justify-start py-0 pr-0 pl-5 box-border gap-[15px] max-w-full">
              <ContactForm />
            </div>
          </div>
          {/* Added hidden md:block to hide on mobile but show on medium screens and up */}
          <div className="hidden md:flex w-[704px] h-[298px] flex-col items-start justify-start py-0 px-[152px] box-border max-w-full text-5xl mq450:pl-5 mq450:pr-5 mq450:box-border mq900:pl-[76px] mq900:pr-[76px] mq900:box-border">
            <div className="ml-[-187px] flex flex-col items-start justify-start pt-[34px] pb-[30px] pr-[137px] pl-[186px] box-border relative gap-[90px] max-w-[147%] shrink-0 z-[1] mq450:gap-[22px] mq450:pl-5 mq450:pr-5 mq450:box-border mq700:gap-[45px] mq700:pl-[93px] mq700:pr-[68px] mq700:box-border">
              <div className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] shadow-[0px_0px_50px_rgba(0,_46,_66,_0.15)] rounded-tl-none rounded-tr-13xl rounded-br-13xl rounded-bl-none bg-color-background-white" />
              <h3 className="m-0 relative text-inherit tracking-[-0.02em] font-bold font-inherit z-[1] mq450:text-lgi">
                Give us a call
              </h3>
              <div className="flex flex-col items-start justify-start gap-[6px]">
                <h3 className="m-0 relative text-inherit tracking-[-0.02em] font-bold font-inherit z-[1] mq450:text-lgi">
                  Our address
                </h3>
                <div className="relative text-base tracking-[-0.02em] font-medium z-[1]">
                  <p className="m-0">Unit 201, Level 1</p>
                  <p className="m-0">Gate Avenue South Zone</p>
                  <p className="m-0">Dubai International Financial Centre</p>
                  <p className="m-0">Dubai, UAE</p>
                </div>
              </div>
            </div>
            <button className="cursor-pointer [border:none] py-2.5 px-5 bg-color-background-jade rounded-6xs flex flex-row items-start justify-start gap-[10px] z-[2] mt-[-222px]">
              <div className="flex flex-col items-start justify-start pt-[2.5px] px-0 pb-0">
                <div className="flex flex-row items-center justify-center w-3.5 h-3.5">
                  <img
                    className="h-3.5 w-3.5 relative"
                    alt=""
                    src="/vector-10.svg"
                  />
                </div>
              </div>
              <div className="relative text-base tracking-[-0.02em] font-semibold font-h4 text-color-background-white text-left inline-block min-w-[129px] whitespace-nowrap">
                +971 4 298 6553
              </div>
              <div className="hidden flex-row items-center justify-center w-3.5 h-3.5">
                <img className="h-3.5 w-3.5 relative" alt="" />
              </div>
            </button>
          </div>
        </div>
        <footer className="self-stretch flex flex-row items-start justify-end max-w-full text-center text-13xl text-color-text-charcoal font-h4">
          <div className="w-[1677px] flex flex-col items-start justify-start py-0 pr-0 pl-5 box-border gap-[53px] max-w-full mq700:gap-[26px]">
            <div className="self-stretch flex flex-row items-start justify-end max-w-full">
              <div className="w-[687px] flex flex-col items-end justify-start gap-[30px] max-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[15px] max-w-full">
                </div>
                <div className="w-[675px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[20px] max-w-full text-left text-5xl">
              <h3 className="m-0 relative text-inherit tracking-[-0.02em] font-bold font-inherit z-[1] mq450:text-lgi">
                Connect with us on social media
              </h3>
              <div className="flex flex-row items-start justify-start gap-[30px] z-[1]">
                <a 
                  href="https://www.linkedin.com/company/3-shepherd/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer transition-opacity hover:opacity-80"
                >
                  <img
                    className="h-[24.5px] w-[24.6px] relative"
                    loading="lazy"
                    alt="LinkedIn"
                    src="/linekdin-icon.svg"
                  />
                </a>
                <img
                  className="h-[26px] w-7 relative min-h-[26px]"
                  loading="lazy"
                  alt=""
                  src="/x-icon.svg"
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ContactUs;