import React, { useState } from 'react';
import PropTypes from "prop-types";
import Input1 from "./Input1";
import Button from "./Button";

const SignUpPopup = ({ className = "", onClose }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Email validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validate email
    if (!email) {
      setError("Please enter your email address");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: 'b164fdc4-dfa7-4608-9ca5-0001c5a97fb3',
          from_name: '3 Shepherd Waitlist',
          email: email,
          subject: 'New Waitlist Signup',
          // Web3Forms built-in spam protection - no need for custom honeypot
        })
      });

      const data = await response.json();
      
      if (data.success) {
        setIsSuccess(true);
        setTimeout(() => {
          onClose?.();
        }, 2000);
      } else {
        throw new Error(data.message || 'Submission failed');
      }
    } catch (err) {
      console.error('Submission error:', err);
      setError("Failed to join waitlist. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form 
      onSubmit={handleSubmit}
      className={`w-[722px] shadow-[0px_0px_50px_rgba(0,_46,_66,_0.15)] rounded-[23px] 
        bg-color-background-white overflow-hidden flex flex-col items-start 
        justify-between p-10 box-border relative min-h-[295px] 
        max-w-[95vw] mx-auto
        max-tablet:p-6 
        max-mobile:p-4 max-mobile:min-h-0 max-mobile:w-full max-mobile:rounded-lg
        ${className}`}
    >
      <section className="self-stretch flex flex-col items-start justify-start gap-[5px]">
        <h1 className="m-0 text-[40px] tracking-[-0.02em] font-bold text-color-text-charcoal
          max-mobile:text-[24px]">
          Join the waiting list
        </h1>
        <div className="text-base tracking-[-0.02em] font-medium text-color-text-charcoal
          max-mobile:text-sm max-mobile:mt-1">
          We're not live quite yet, but you can join the waiting list to be
          notified as soon as we launch 3 Shepherd!
        </div>
      </section>

      <div className="flex flex-col items-start justify-start gap-[15px] w-full mt-6">
        <Input1
          placeholderText="Enter your email"
          errorInfo={error}
          required={true}
          showErrorInfoText={!!error}
          showDescriptionText={false}
          showDescriptiveText={false}
          showTypeIcon
          showAccessoryIcon={false}
          showExpandHandle={false}
          labelText="Email"
          showInputLabel={false}
          propWidth="14px"
          propHeight="14px"
          propPadding="unset"
          propHeight1="11px"
          propWidth1="15px"
          propTransform="unset"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError("");
          }}
          className="max-mobile:text-sm"
        />
        
        <Button
          type="submit"
          disabled={isSubmitting}
          showTrailingIcon={false}
          showLeadingIcon={false}
          showButtonText
          text={isSubmitting ? "Signing up..." : isSuccess ? "Successfully joined!" : "Sign up"}
          propBackgroundColor={isSuccess ? "#4CAF50" : "#005de0"}
          propWidth="100%"
          propHeight="unset"
          propAlignItems="center"
          propMinWidth="unset"
          propPadding="12px 16px"
          vector1="pending_I65:1349;2311:190;2313:11"
          propWidth1="14px"
          propTransform="unset"
          className="max-mobile:text-sm"
        />

        {isSuccess && (
          <div className="text-green-600 text-sm mt-2">
            Successfully joined the waiting list! Redirecting...
          </div>
        )}
      </div>

      <img
  style={{
    width: 'clamp(60px, 8vw, 99.2px)',
    height: 'clamp(60px, 8vw, 99.2px)',
    position: 'absolute',
    right: 'clamp(1rem, 4vw, 33.8px)',
    bottom: 'clamp(4rem, 8vw, 30.1px)',
    objectFit: 'contain'
  }}
  loading="lazy"
  alt="Notification Bell"
  src="/Notidication Bell.svg"
/>
    </form>
  );
};

export default SignUpPopup;