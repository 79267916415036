// ContactForm.jsx
import { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setError("Please enter your name");
      return false;
    }
    if (!formData.email.trim()) {
      setError("Please enter your email");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email");
      return false;
    }
    if (!formData.message.trim()) {
      setError("Please enter your message");
      return false;
    }
    if (!isChecked) {
      setError("Please agree to the terms and conditions");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: 'b164fdc4-dfa7-4608-9ca5-0001c5a97fb3',
          name: formData.name,
          email: formData.email,
          message: formData.message,
          from_name: "3 Shepherd Contact Form",
          subject: "New Contact Form Submission",
        })
      });

      const data = await response.json();
      
      if (data.success) {
        setIsSuccess(true);
        setFormData({ name: "", email: "", message: "" });
        setIsChecked(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      } else {
        throw new Error(data.message || 'Submission failed');
      }
    } catch (err) {
      console.error('Submission error:', err);
      setError("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="self-stretch flex flex-col gap-[15px] w-full">
      <div className="self-stretch flex flex-col md:flex-row items-start justify-start gap-[20px] max-w-full">
        <div className="w-full md:w-[370px] flex flex-col items-start justify-start gap-[15px]">
          {/* Name Input */}
          <div className="self-stretch rounded-6xs bg-color-background-white box-border flex flex-row items-start justify-start py-2 px-[9px] gap-[10px] max-w-full border-[1px] border-solid border-color-border-light-gray">
            <input
              className="w-full [border:none] [outline:none] font-medium font-h4 text-base bg-[transparent] relative tracking-[-0.02em] text-color-text-gray text-left flex items-center p-0"
              placeholder="Your name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>

          {/* Email Input */}
          <div className="self-stretch rounded-6xs bg-color-background-white box-border flex flex-row items-start justify-start py-2 px-[9px] gap-[10px] max-w-full border-[1px] border-solid border-color-border-light-gray">
            <input
              className="w-full [border:none] [outline:none] font-medium font-h4 text-base bg-[transparent] relative tracking-[-0.02em] text-color-text-gray text-left flex items-center p-0"
              placeholder="Your email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Message Input */}
        <div className="w-full md:w-[500px] self-stretch rounded-6xs bg-color-background-white box-border flex flex-row items-start justify-start py-2 px-[9px] gap-[10px] border-[1px] border-solid border-color-border-light-gray">
          <textarea
            className="w-full [border:none] [outline:none] font-medium font-h4 text-base bg-[transparent] relative tracking-[-0.02em] text-color-text-gray text-left p-0 resize-none min-h-[113px]"
            placeholder="Your message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            rows={4}
          />
        </div>
      </div>

      {error && (
        <div className="text-color-background-danger text-sm">{error}</div>
      )}

      {isSuccess && (
        <div className="text-color-background-jade text-sm">Message sent successfully!</div>
      )}

      <div className="self-stretch flex flex-col md:flex-row items-start justify-between max-w-full gap-[20px] text-base">
        {/* Checkbox */}
        <div className="w-full md:w-[370px] flex flex-row items-start justify-start gap-[20px] max-w-full z-[1]">
          <div className="flex flex-col items-start justify-start pt-1.5 px-0 pb-0">
            <input
              className="m-0 w-[15px] h-[15px] relative rounded-[5px] box-border border-[1px] border-solid border-color-text-gray"
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
          </div>
          <div className="flex-1 relative tracking-[-0.02em] text-sm inline-block min-w-[211px] max-w-full">
            Agree to our terms, conditions according to our privacy policy
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full md:w-auto cursor-pointer [border:none] py-2.5 px-5 bg-color-background-jade rounded-6xs flex flex-row items-center justify-center gap-[10px] z-[1]"
        >
          <div className="relative text-base tracking-[-0.02em] font-semibold font-h4 text-color-background-white text-center">
            {isSubmitting ? "Sending..." : "Send message"}
          </div>
        </button>
      </div>
    </form>
  );
};
export default ContactForm;