import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './components/AuthProvider'; // Adjust the path as necessary
import ThemeProvider from './pages/panel/utils/ThemeContext';

import AdminPanel from "./pages/panel/AdminPanel";
import Panel from "./pages/panel/Panel";
import UserPanel from "./pages/panel/UserPanel";
import SignUp from './components/Auth/SignUp';
import ForgotPassword from './components/Auth/ForgotPassword';

import BusinessRegister from './components/Auth/BusinessRegister';
import BusinessVerification from './components/Auth/BusinessVerification';
import {SignIn, UaePassCallbackHandler } from './components/Auth/SignIn';
import Home from './pages/Home';
import Admin from './pages/Admin';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/Contact/ContactUs';
import MerchantProfile from './pages/merchantpages/MerchantProfile';
import PrivateRoute from './components/PrivateRoute';
import Navigation from './components/Navigation';
import './global.css';
import Tracking from './components/FedEx/tracking';
import CreateShipmentForm from './components/FedEx/CreateShipment';
import Checkout from './components/checkout/Checkout';
import AdminUserList from './pages/panel/admincomps/AdminUserList';
import Orders from './pages/userpages/Orders';
import OrdersTable from './components/Merchant/OrdersTable';
import RequestPickup from './components/Merchant/RequestPickup';
import MerchantSettings from './components/Merchant/Settings';
import BlockExpressCheckoutWal from './pages/button/BlockExpressCheckoutWal';
import UserProfile from './pages/userpages/UserProfile';
import Feedback from './pages/userpages/Feedback';
import MerchantFeedback from './pages/merchantpages/Feedback';
import InviteUsers from './pages/merchantpages/InviteUsers';
import Disputes from './pages/adminpages/Disputes';



const CLIENT_ID = '29330502523-qftoqtsjmtenl9kpqn36985aq2fugduk.apps.googleusercontent.com';

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Router>  {/* Wrap everything inside AuthProvider */}
        <AuthProvider>
        <ThemeProvider>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/auth/uaepass/callback" element={<UaePassCallbackHandler />} />
            <Route path="/button" element={<BlockExpressCheckoutWal />} />
            <Route path="/adminpanel" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><AdminPanel /></PrivateRoute>} />
            <Route path="/panel" element={<PrivateRoute allowedRoles={['merchant', 'owner', 'shipping', 'finance']}><Panel /></PrivateRoute>} />
            <Route path="/userpanel" element={<PrivateRoute allowedRoles={['user']}><UserPanel /></PrivateRoute>} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/business-register" element={<BusinessRegister />} />
            <Route path="/business-verification" element={<BusinessVerification />} />
            <Route path="/admin" element={<PrivateRoute allowedRoles={['admin', 'owner']}><Admin /></PrivateRoute>} />
            <Route path="/adminuserlist" element={<PrivateRoute><AdminUserList /></PrivateRoute>} />
            <Route path="/disputes" element={<PrivateRoute><Disputes /></PrivateRoute>} />
            <Route path="/tracking/:trackingNumber/:orderId" element={<PrivateRoute><Tracking /></PrivateRoute>} />
            <Route path="/tracking" element={<PrivateRoute><Tracking /></PrivateRoute>} />
            <Route path="/user-orders" element={<PrivateRoute allowedRoles={['user']}><Orders /></PrivateRoute>} />
            <Route path="/user-profile" element={<PrivateRoute allowedRoles={['user']}><UserProfile /></PrivateRoute>} />
            <Route path="/user-profile/feedback" element={<PrivateRoute allowedRoles={['user']}><Feedback /></PrivateRoute>} />
            <Route path="/merchant-profile" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><MerchantProfile /></PrivateRoute>} />
            <Route path="/merchant-profile/feedback" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><MerchantFeedback /></PrivateRoute>} />
            <Route path="/invite-users" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><InviteUsers /></PrivateRoute>} />
            <Route path="/merchant-orders" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><OrdersTable /></PrivateRoute>} />
            <Route path="/requestpickup" element={<PrivateRoute><RequestPickup /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><MerchantSettings /></PrivateRoute>} />
            <Route path="/createshipment" element={<PrivateRoute allowedRoles={['merchant', 'owner']}><CreateShipmentForm /></PrivateRoute>} />
            <Route path="/checkout" element={<Checkout />} />
          </Routes>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
    </>
  );
};

export default App;
