import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, CssBaseline, Typography, Avatar, Button, TextField, Grid } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../services/api';

const theme = createTheme();

export default function BusinessRegister() {
  const navigate = useNavigate();
  const [businessForm, setBusinessForm] = useState({
    firstName: '', lastName: '', username: '', email: '', password: '',
    businessName: '', businessAddress: '', registrationNumber: ''
  });
  const [reference, setReference] = useState(null);
  const [business_address, setBusinessAddress] = useState(null);
  const handleChange = (e) => {
    setBusinessForm({ ...businessForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/auth/business-register', {
        username: businessForm.username,
        password: businessForm.password,
        name: `${businessForm.firstName} ${businessForm.lastName}`,
        email: businessForm.email,
        business_name: businessForm.businessName,
        business_address: businessForm.businessAddress,
        registration_number: businessForm.registrationNumber
      });
      setReference(response.data.reference);
      console.log('address:', business_address); //debugging
      alert('Business registered successfully');
    } catch (error) {
      console.error('Business registration failed:', error);
      alert('Registration failed');
    }
  };

  useEffect(() => {
    if (reference && business_address) {
      navigate('/business-verification', { state: { reference } });
    }
  }, [reference, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Proceed to Verification
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={businessForm.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={businessForm.lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={businessForm.username}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={businessForm.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={businessForm.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="businessName"
                  label="Business Name"
                  name="businessName"
                  autoComplete="business-name"
                  value={businessForm.businessName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="businessAddress"
                  label="Business Address"
                  name="businessAddress"
                  autoComplete="business-address"
                  value={businessForm.businessAddress}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="registrationNumber"
                  label="Registration Number"
                  name="registrationNumber"
                  autoComplete="registration-number"
                  value={businessForm.registrationNumber}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Proceed to Verification
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}