import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Select,
  SelectItem,
  TextInput,
  Flex,
  Dialog,
  DialogPanel,
} from "@tremor/react";
import api from '../../services/api';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../AuthProvider';

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState({}); // Track loading state per order
  const { getUser } = useAuth();
  const userData = getUser();
  const merchantId = userData?.id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(`/orders/merchant/${merchantId}`);
        setOrders(response.data);
        setFilteredOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    if (merchantId) {
      fetchOrders();
    }
  }, [merchantId]);

  useEffect(() => {
    filterOrders();
  }, [statusFilter, searchTerm, orders]);

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterOrders = () => {
    let filtered = orders;
    if (statusFilter) {
      filtered = filtered.filter(order => order.status === statusFilter);
    }
    if (searchTerm) {
      const lowercasedSearch = searchTerm.toLowerCase();
      filtered = filtered.filter(order => {
        const orderId = String(order.order_id).toLowerCase();
        const userId = String(order.user_id).toLowerCase();
        const trackingNumber = order.tracking_number ? String(order.tracking_number).toLowerCase() : '';
        
        return orderId.includes(lowercasedSearch) ||
               userId.includes(lowercasedSearch) ||
               trackingNumber.includes(lowercasedSearch);
      });
    }
    setFilteredOrders(filtered);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "delivered":
        return "emerald";
      case "processing":
        return "blue";
      case "cancelled":
        return "red";
      default:
        return "gray";
    }
  };

  const handleCreateShipment = async (orderId) => {
    setIsLoading(prev => ({ ...prev, [orderId]: true }));
    try {
      // Update the endpoint to match backend
      const response = await api.post(`/fedex/generate-label/${orderId}`);
      
      // Update the order in the list with the new tracking number
      const updatedOrders = orders.map(order => {
        if (order.order_id === orderId) {
          return {
            ...order,
            tracking_number: response.data.trackingNumber,
            status: 'processing' // Optionally update status if needed
          };
        }
        return order;
      });
      
      setOrders(updatedOrders);
      alert('Shipment created successfully!');
    } catch (error) {
      console.error('Error creating shipment:', error);
      // More detailed error message
      alert(error.response?.data?.error || 'Failed to create shipment. Please try again.');
    } finally {
      setIsLoading(prev => ({ ...prev, [orderId]: false }));
    }
  };

  const handleCancelOrder = async (orderId) => {
    try {
      await api.post(`/orders/${orderId}/cancel`);
      // Refresh the orders after cancellation
      const response = await api.get(`/orders/merchant/${merchantId}`);
      setOrders(response.data);
      setFilteredOrders(response.data);
    } catch (error) {
      console.error('Error canceling order:', error);
    }
  };

  const handleViewDetails = async (orderId) => {
    try {
      const response = await api.get(`/users/${orderId}/user-details`);
      setSelectedOrderDetails(response.data);
      setIsDialogOpen(true);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  return (
    <Card className="mt-4 mb-4">
      <Title>Orders for {userData.name}</Title>
      <Flex className="mt-4 mb-4">
        <Select 
          onValueChange={handleStatusFilterChange} 
          value={statusFilter} 
          placeholder="Filter by Status"
        >
          <SelectItem value="">All</SelectItem>
          <SelectItem value="delivered">Delivered</SelectItem>
          <SelectItem value="processing">Processing</SelectItem>
          <SelectItem value="cancelled">Cancelled</SelectItem>
        </Select>
        <TextInput
          placeholder="Search by Order ID, User ID or Tracking Number"
          onChange={handleSearchChange}
          value={searchTerm}
          className="ml-2"
        />
      </Flex>
      <Table className="mt-5">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Order ID</TableHeaderCell>
            <TableHeaderCell>User ID</TableHeaderCell>
            <TableHeaderCell>Amount</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Tracking Number</TableHeaderCell>
            <TableHeaderCell>Created At</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrders.map((order) => (
            <TableRow key={order.order_id}>
              <TableCell>{order.order_id}</TableCell>
              <TableCell>
                <Text>{order.user_id}</Text>
              </TableCell>
              <TableCell>
                <Text>AED {order.amount}</Text>
              </TableCell>
              <TableCell>
                <Badge color={getStatusColor(order.status)}>
                  {order.status}
                </Badge>
              </TableCell>
              <TableCell>
                <Text>{order.tracking_number}</Text>
              </TableCell>
              <TableCell>
                <Text>{new Date(order.createdAt).toLocaleString()}</Text>
              </TableCell>
              <TableCell>
                <Flex justifyContent="between" gap="2">
                  <Button 
                    variant="secondary" 
                    onClick={() => handleCreateShipment(order.order_id)}
                    disabled={
                      order.status !== "processing" || 
                      order.tracking_number || 
                      isLoading[order.order_id]
                    }
                    loading={isLoading[order.order_id]}
                  >
                    Create Shipment
                  </Button>
                  <Button 
                    variant='secondary' 
                    onClick={() => navigate(`/tracking/${order.tracking_number}/${order.order_id}`)} 
                    disabled={order.status === "cancelled" || order.status === "delivered"}
                  >
                    Track Order
                  </Button>
                  <Button 
                    variant="secondary" 
                    color='blue' 
                    onClick={() => handleViewDetails(order.order_id)}
                  >
                    View Customer Details
                  </Button>
                  <Button 
                    variant='secondary' 
                    color="red" 
                    onClick={() => handleCancelOrder(order.order_id)} 
                    disabled={order.status === "cancelled" || order.status === "delivered"}
                  >
                    Cancel Order
                  </Button>
                </Flex>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogPanel>
          <h3 className="text-lg font-semibold">Customer Details</h3>
          {selectedOrderDetails ? (
            <div>
              <p>Name: {selectedOrderDetails.name}</p>
              <p>Email: {selectedOrderDetails.email}</p>
              <p>Address: {selectedOrderDetails.address} {selectedOrderDetails.phone}</p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          <Button className="mt-8 w-full" onClick={() => setIsDialogOpen(false)}>
            Close
          </Button>
        </DialogPanel>
      </Dialog>
    </Card>
  );
};

export default OrdersList;