import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from '@mui/material';

const MerchantList = () => {
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await api.get('/business/merchants');
        setMerchants(response.data);
      } catch (error) {
        console.error('Failed to fetch merchants:', error);
      }
    };
    fetchMerchants();
  }, []);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/business/merchants/${id}`);
      setMerchants(merchants.filter((merchant) => merchant.id !== id));
    } catch (error) {
      console.error('Failed to delete merchant:', error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ p: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="h6">ID</Typography></TableCell>
            <TableCell><Typography variant="h6">Username</Typography></TableCell>
            <TableCell><Typography variant="h6">Name</Typography></TableCell>
            <TableCell><Typography variant="h6">Email</Typography></TableCell>
            <TableCell><Typography variant="h6">Business Name</Typography></TableCell>
            <TableCell><Typography variant="h6">Business Address</Typography></TableCell>
            <TableCell><Typography variant="h6">Registration Number</Typography></TableCell>
            <TableCell><Typography variant="h6">Actions</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {merchants.map((merchant) => (
            <TableRow key={merchant.id}>
              <TableCell>{merchant.id}</TableCell>
              <TableCell>{merchant.username}</TableCell>
              <TableCell>{merchant.name}</TableCell>
              <TableCell>{merchant.email}</TableCell>
              <TableCell>{merchant.business_name}</TableCell>
              <TableCell>{merchant.business_address}</TableCell>
              <TableCell>{merchant.registration_number}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(merchant.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MerchantList;
