import { useCallback, useState } from "react";

const WPHeader = ({ className = "", children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const onMenuItemContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='fold']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative w-full">
      <div
        className={`flex-1 shadow-[0px_0px_50px_rgba(0,_46,_66,_0.15)] rounded-2xl bg-color-background-white 
          flex flex-row items-center justify-between py-2.5 px-5 box-border max-w-full
          z-[2] text-left text-base text-color-text-charcoal font-callout-body ${className}`}
      >
        {/* Logo Section */}
        <div className="flex flex-row items-center justify-start gap-[15px]">
          <img
            className="h-12 w-12 relative object-cover"
            loading="lazy"
            alt=""
            src="/3s-logo-icon-only@2x.png"
          />
          <img
            className="h-5 w-[140.9px] relative object-contain"
            loading="lazy"
            alt=""
            src="/Color logo - no background 1.png"
          />
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex flex-row items-center justify-end gap-8">
          {children}
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden rounded-6xs flex flex-row items-center justify-center py-[12.5px] px-3 cursor-pointer"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label="Toggle menu"
        >
          <div className={`w-6 h-6 relative transform transition-transform duration-300 ${isMenuOpen ? 'rotate-180' : ''}`}>
            <span className={`block absolute h-0.5 w-full bg-gray-600 transform transition-all duration-300 
              ${isMenuOpen ? 'rotate-45 translate-y-2.5' : '-translate-y-2'}`}></span>
            <span className={`block absolute h-0.5 w-full bg-gray-600 transform transition-all duration-300 
              ${isMenuOpen ? 'opacity-0' : 'opacity-100'}`}></span>
            <span className={`block absolute h-0.5 w-full bg-gray-600 transform transition-all duration-300 
              ${isMenuOpen ? '-rotate-45 -translate-y-2.5' : 'translate-y-2'}`}></span>
          </div>
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      <div 
        className={`absolute top-full left-0 right-0 bg-color-background-white shadow-lg rounded-b-2xl 
          overflow-hidden transition-all duration-300 ease-in-out z-[1] md:hidden
          ${isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
      >
        <div className="flex flex-col items-start w-full py-2">
          {Array.isArray(children) 
            ? children.map((child, index) => (
                <div 
                  key={index} 
                  className="w-full px-5 py-3 hover:bg-gray-50 transition-colors duration-200"
                >
                  {child}
                </div>
              ))
            : <div className="w-full px-5 py-3 hover:bg-gray-50 transition-colors duration-200">
                {children}
              </div>
          }
        </div>
      </div>
    </div>
  );
};

export default WPHeader;