import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <section
      id="how-it-works"
      className={`w-full max-w-[1895px] flex flex-col items-center justify-center pt-0 px-4 sm:px-6 md:px-8 pb-[94px] box-border text-center text-3xl sm:text-4xl md:text-5xl lg:text-21xl text-color-text-charcoal font-callout-body mq825:pb-10 mq1250:pb-[61px] ${className}`}
    >
      <div className="w-full max-w-[1133px] flex flex-col items-center justify-start gap-[34.8px] mq825:gap-[17px]">
        <div className="w-full flex flex-col items-center justify-start pb-[25.2px] box-border">
        <h1 className="m-0 w-[393px] relative text-inherit tracking-[-0.02em] font-bold font-[inherit] text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex items-center justify-center mq450:text-19xl mq825:text-32xl">
                How it works
              </h1>
          <div className="w-full max-w-[957px] text-base sm:text-lg md:text-xl lg:text-5xl tracking-[-0.02em] font-medium mt-4">
            We work with verified user banking accounts and transaction-based escrow accounts.
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center pt-0 pb-[41.2px] pl-[45px] pr-5 box-border max-w-full text-5xl text-color-background-white mq1250:pl-[22px] mq1250:box-border">
          <div className="w-[812px] shadow-[0px_0px_50px_rgba(0,_46,_66,_0.15)] rounded-80xl bg-color-primary-bright-jade flex flex-row items-start justify-start py-[15px] px-[75px] box-border max-w-full mq825:pl-[37px] mq825:pr-[37px] mq825:box-border">
            <div className="flex-1 relative tracking-[-0.02em] font-medium inline-block max-w-full mq450:text-lgi">
              This means we accept payment from verified buyers and immediately
              reserve the funds in a secure escrow account.
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row items-center justify-between gap-8 text-left">
          <div className="w-full md:w-1/2 lg:w-[372px] relative aspect-[372/667] max-w-full">
            <img
              className="absolute top-0 right-0 w-full h-full object-cover mix-blend-multiply z-[1]"
              alt=""
              src="/Group 6152.png"
            />
            {/* Phone mockup content */}
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-start justify-start">
            <div className="rounded-t-4xs rounded-br-4xs rounded-bl-10xs flex flex-col items-start justify-start p-4 gap-[5px] z-[5]">
              <h2 className="m-0 text-2xl sm:text-3xl md:text-4xl lg:text-21xl tracking-[-0.02em] font-bold">
                Look for our logo during checkout
              </h2>
              <div className="text-base sm:text-lg md:text-xl lg:text-5xl tracking-[-0.02em] font-sm text-color-text-gray mt-2">
                While shopping on your favourite online store, just tick the box that says "Secure my transaction against fraud by paying via 3 Shepherd."
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[681px] rounded-t-4xs rounded-br-4xs rounded-bl-10xs flex flex-col items-start justify-start p-4 gap-[5px] text-left">
          <h2 className="m-0 text-2xl sm:text-3xl md:text-4xl lg:text-21xl tracking-[-0.02em] font-bold">
            Your payment gets immediately secured
          </h2>
          <div className="text-base sm:text-lg md:text-xl lg:text-5xl tracking-[-0.02em] font-medium text-color-text-gray mt-2">
            We put your payment in a temporary Escrow account while the merchant processes your order for shipment, securing your money against online transaction fraud.
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;