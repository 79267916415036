import React from "react";
import Navbar from "../../components/navbar/Navbar";
import AdminSidebar from "../../components/sidebar/AdminSidebar";
import DisputeTable from "../panel/admincomps/DisputeTable"; // Adjust the import path to your UserTable component

const AdminUserList = () => {
  return (
    <main className="flex">
      <AdminSidebar />
      <div className="flex flex-col flex-1 relative">
        <Navbar />
        <div className="grid md:grid-cols-3 grid-cols-1 w-full">
          <div className="col-span-3">
            <DisputeTable />
          </div>
        </div>
      </div>
    </main>
  );
}

export default AdminUserList;