import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Text,
  Title,
  Badge,
  Button,
  Flex,
  Divider,
  List,
  ListItem,
  TextInput,
  Textarea
} from "@tremor/react";
import {
  XIcon,
  DownloadIcon,
  CheckCircleIcon,
  ExclamationIcon,
  ClockIcon,
  DocumentTextIcon
} from "@heroicons/react/solid";
import api from "../../../services/api";

const DisputeDetailsDrawer = ({ isOpen, onClose, disputeId }) => {
  const [loading, setLoading] = useState(true);
  const [dispute, setDispute] = useState(null);
  const [notes, setNotes] = useState("");

  const fetchDisputeDetails = useCallback(async () => {
    if (!disputeId) return;
    
    try {
      setLoading(true);
      const response = await api.get(`/disputes/${disputeId}/details`);
      setDispute(response.data);
      setNotes(response.data.evidence?.notes || "");
    } catch (error) {
      console.error("Error fetching dispute details:", error);
    } finally {
      setLoading(false);
    }
  }, [disputeId]);

  useEffect(() => {
    if (isOpen && disputeId) {
      fetchDisputeDetails();
    }
  }, [isOpen, disputeId, fetchDisputeDetails]);

  // Reset state when drawer closes
  useEffect(() => {
    if (!isOpen) {
      setDispute(null);
      setNotes("");
    }
  }, [isOpen]);

  // Handle click outside
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('bg-opacity-50')) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleGenerateEvidence = async () => {
    try {
      await api.post(`/disputes/${disputeId}/evidence/generate`);
      await fetchDisputeDetails();
    } catch (error) {
      console.error("Error generating evidence:", error);
    }
  };

  const handleSubmitEvidence = async () => {
    try {
      await api.post(`/disputes/${disputeId}/evidence/submit`);
      await fetchDisputeDetails();
    } catch (error) {
      console.error("Error submitting evidence:", error);
    }
  };

  const handleUpdateNotes = async () => {
    try {
      await api.put(`/disputes/${disputeId}/notes`, { notes });
      await fetchDisputeDetails();
    } catch (error) {
      console.error("Error updating notes:", error);
    }
  };
  const handleDownloadEvidence = async (disputeId) => {
    try {
      const response = await api.get(`/disputes/${disputeId}/evidence/download`, {
        responseType: 'blob',
        timeout: 30000, // 30 second timeout
        headers: {
          Accept: 'application/pdf'
        }
      });
  
      // Check if we got a successful response with data
      if (!response.data || response.status !== 200) {
        throw new Error('Failed to download evidence file');
      }
  
      // Create blob URL
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
  
      // Create temporary link and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = `evidence-${disputeId}.pdf`;
      document.body.appendChild(link);
      link.click();
  
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error('Error downloading evidence:', error);
      
      // Handle different types of errors
      if (error.response) {
        // Server responded with error
        const errorMessage = error.response.data?.error || 'Failed to download evidence';
        alert(`Download failed: ${errorMessage}`);
      } else if (error.request) {
        // Request was made but no response
        alert('Unable to connect to server. Please try again.');
      } else {
        // Something else went wrong
        alert('Error downloading evidence. Please try again.');
      }
    }
  };


  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50"
      onClick={handleClickOutside}
    >
      <div className="absolute right-0 top-0 h-full w-2/5 bg-white shadow-lg">
        {loading ? (
          <div className="p-6">Loading...</div>
        ) : dispute ? (
          <div className="h-full flex flex-col">
            <div className="p-6 border-b">
              <Flex justifyContent="between" alignItems="center">
                <Title>Dispute Details</Title>
                <Button 
                  icon={XIcon} 
                  variant="secondary" 
                  onClick={onClose}
                  className="hover:bg-gray-100"
                >
                  Close
                </Button>
              </Flex>
            </div>
  
            <div className="flex-1 overflow-y-auto p-6">
              <Card>
                <Title>Basic Information</Title>
                <List>
                  <ListItem>
                    <Text>Dispute ID: {dispute?.stripe_dispute_id}</Text>
                  </ListItem>
                  <ListItem>
                    <Text>Amount: {dispute?.amount} {dispute?.currency}</Text>
                  </ListItem>
                  <ListItem>
                    <Text>Reason: {dispute?.reason}</Text>
                  </ListItem>
                  <ListItem>
                    <Text>Status: </Text>
                    <Badge color={getStatusColor(dispute?.status)}>
                      {dispute?.status?.replace(/_/g, ' ').toUpperCase()}
                    </Badge>
                  </ListItem>
                </List>
              </Card>
  
              <Card className="mt-4">
                <Title>Order Information</Title>
                <List>
                  <ListItem>
                    <Text>Order ID: {dispute?.order?.id}</Text>
                  </ListItem>
                  <ListItem>
                    <Text>Order Amount: {dispute?.order?.amount}</Text>
                  </ListItem>
                  <ListItem>
                    <Text>Order Status: {dispute?.order?.status}</Text>
                  </ListItem>
                  <ListItem>
                    <Text>Merchant: {dispute?.order?.merchant?.name}</Text>
                  </ListItem>
                </List>
              </Card>
  
              <Card className="mt-4">
                <Title>Evidence</Title>
                {dispute?.evidence ? (
                  <>
                    <List>
                      <ListItem>
                        <Text>Status: </Text>
                        <Badge color={getEvidenceStatusColor(dispute.evidence.status)}>
                          {dispute.evidence.status.toUpperCase()}
                        </Badge>
                      </ListItem>
                      <ListItem>
                        <Text>Generated: {new Date(dispute.evidence.generated_at).toLocaleString()}</Text>
                      </ListItem>
                    </List>
                    
                    <Divider />
                    
                    <Text>Notes:</Text>
                    <Textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Add notes about this dispute..."
                      className="mt-2"
                    />
                    
                    <Flex className="mt-4 gap-2">
                      <Button
                        icon={DownloadIcon}
                        onClick={() => handleDownloadEvidence(disputeId)}
                      >
                        Download Report
                      </Button>
                      <Button
                        icon={DocumentTextIcon}
                        onClick={handleUpdateNotes}
                      >
                        Update Notes
                      </Button>
                    </Flex>
                  </>
                ) : (
                  <Flex className="mt-4" justifyContent="center">
                    <Button
                      icon={DocumentTextIcon}
                      onClick={handleGenerateEvidence}
                    >
                      Generate Evidence
                    </Button>
                  </Flex>
                )}
              </Card>
  
              {dispute?.evidence && dispute.evidence.status !== 'submitted' && (
                <Card className="mt-4">
                  <Button
                    icon={CheckCircleIcon}
                    onClick={handleSubmitEvidence}
                    color="green"
                    className="w-full"
                  >
                    Submit Evidence to Stripe
                  </Button>
                </Card>
              )}
            </div>
          </div>
        ) : (
          <div className="p-6">
            <Text>Dispute not found</Text>
          </div>
        )}
      </div>
    </div>
  );
}

const getStatusColor = (status) => {
  const statusColors = {
    'needs_response': 'red',
    'under_review': 'yellow',
    'won': 'green',
    'lost': 'gray',
    'warning_closed': 'orange',
    'manual_review': 'purple'
  };
  return statusColors[status] || 'gray';
};

const getEvidenceStatusColor = (status) => {
  const statusColors = {
    'pending': 'yellow',
    'submitted': 'blue',
    'accepted': 'green',
    'rejected': 'red'
  };
  return statusColors[status] || 'gray';
};

export default DisputeDetailsDrawer;