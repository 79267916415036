import React, { useState } from 'react';
import { useAuth } from '../../components/AuthProvider';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { msalInstance } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // For Azure B2C, we redirect to the password reset policy
      await msalInstance.loginPopup({
        authority: 'https://3shep.b2clogin.com/3shep.onmicrosoft.com/B2C_1_PasswordReset',
        scopes: ['openid'],
        prompt: 'login',
        loginHint: email
      });
      
      toast.success('Password reset completed successfully');
    } catch (error) {
      console.error('Password reset error:', error);
      if (error.errorCode === 'user_cancelled') {
        toast.info('Password reset was cancelled');
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;