import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/MerchantNavbar";
import Widget from '../../components/widget/MerchantWidget';
import MerchantNotifications from '../../components/notifications/Notifications';
import ModernChart from '../../components/chart/Chart';
import Table from '../../components/table/ShipmentTable';
import { useAuth } from '../../components/AuthProvider';
import api from '../../services/api';

const Panel = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(true);
  const { getUser, logout } = useAuth();
  const userData = getUser();

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        // Debug logs
        console.log('Auth user:', userData);
        console.log('Organization ID:', userData?.organizationId);
        console.log('API configuration:', {
          baseURL: api.defaults.baseURL,
          headers: api.defaults.headers
        });

        if (!userData?.organizationId) {
          console.log('No organization ID available');
          setLoading(false);
          return;
        }

        // Log request attempt
        console.log('Attempting to fetch organization:', userData.organizationId);
        
        const response = await api.get(`/organizations/${userData.organizationId}`);
        console.log('Organization API Response:', response.data);
        
        if (response.data && response.data.data) {
          setBusinessName(response.data.data.business_name);
        }
      } catch (error) {
        console.error('Error fetching business name:', error);
        // More detailed error logging
        if (error.response) {
          console.log('Error response:', {
            status: error.response.status,
            data: error.response.data
          });
        }
        setBusinessName('');
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessName();
  }, [userData]);

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Main content */}
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <div className="relative bg-blue-200 dark:bg-blue-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
              <div className="relative">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
                  {loading ? (
                    <div className="animate-pulse bg-slate-200 dark:bg-slate-600 h-8 w-64 rounded"></div>
                  ) : (
                    `Good day, ${businessName || 'Welcome'} 👋`
                  )}
                </h1>
                <p className="dark:text-blue-200">Here's what's happening with your business today:</p>
              </div>
            </div>

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left side - You can add components here if needed */}
              <div></div>
              
              {/* Right side actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Add action buttons here if needed */}
              </div>
            </div>

            {/* Widgets section */}
            <div className="grid grid-cols-12 gap-6">
              {/* Widgets - Each should span correct columns */}
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="pending" />
              </div>
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="order" />
              </div>
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="earning" />
              </div>
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="wallet" />
              </div>
            </div>

            {/* Charts sections */}
            <div className="grid grid-cols-12 gap-6 mt-8">
              {/* Notifications - Adjust column span as needed */}
              <div className="col-span-full xl:col-span-6">
                <MerchantNotifications />
              </div>
              {/* Chart */}
              <div className="col-span-full xl:col-span-6">
                <ModernChart title="Last 6 Months (Revenue)" aspect={2 / 1} />
              </div>
            </div>

            {/* Table section */}
            <div className="col-span-full mt-8">
              <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
                <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
                  <h2 className="font-semibold text-slate-800 dark:text-slate-100">Open Shipments</h2>
                </header>
                <Table />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Panel;