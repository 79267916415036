import { useMemo } from "react";
import PropTypes from "prop-types";

const Button = ({
  className = "",
  showTrailingIcon = false,
  showLeadingIcon = false,
  showButtonText = true,
  text = "Pay now",
  propBackgroundColor,
  propWidth,
  propHeight,
  propAlignItems,
  propMinWidth,
  propPadding,
  vector1,
  propWidth1,
  propTransform,
}) => {
  const buttonStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
      width: propWidth,
    };
  }, [propBackgroundColor, propWidth]);

  const buttonTextStyle = useMemo(() => {
    return {
      height: propHeight,
      alignItems: propAlignItems,
      minWidth: propMinWidth,
    };
  }, [propHeight, propAlignItems, propMinWidth]);

  const trailingIconStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const vectorIcon1Style = useMemo(() => {
    return {
      width: propWidth1,
      transform: propTransform,
    };
  }, [propWidth1, propTransform]);

  return (
    <button
      className={`cursor-pointer [border:none] py-2.5 px-5 bg-color-primary-bright-jade w-72 rounded-6xs flex flex-row items-center justify-center box-border gap-2.5 ${className}`}
      style={buttonStyle}
    >
      {showLeadingIcon && (
        <div className="w-3.5 h-3.5 flex flex-row items-center justify-center">
          <img
            className="h-3.5 w-3.5 relative [transform:_rotate(6.6deg)]"
            alt=""
          />
        </div>
      )}
      {showButtonText && (
        <div
          className="h-[19px] relative text-base tracking-[-0.02em] font-semibold font-callout-body text-color-background-white text-left flex items-center"
          style={buttonTextStyle}
        >
          {text}
        </div>
      )}
      {showTrailingIcon && (
        <div
          className="w-3.5 h-3.5 flex flex-row items-center justify-center"
          style={trailingIconStyle}
        >
          <img
            className="h-3.5 w-3.5 relative [transform:_rotate(6.6deg)]"
            alt=""
            src={vector1}
            style={vectorIcon1Style}
          />
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  showTrailingIcon: PropTypes.bool,
  showLeadingIcon: PropTypes.bool,
  showButtonText: PropTypes.bool,
  text: PropTypes.string,
  vector1: PropTypes.string,

  /** Style props */
  propBackgroundColor: PropTypes.string,
  propWidth: PropTypes.string,
  propHeight: PropTypes.string,
  propAlignItems: PropTypes.string,
  propMinWidth: PropTypes.string,
  propPadding: PropTypes.string,
  propWidth1: PropTypes.string,
  propTransform: PropTypes.string,
};

export default Button;
