import FrameComponent4 from "../components/home/FrameComponent4";
import FrameComponent3 from "../components/home/FrameComponent3";
import PayWith3SFace from "../components/home/PayWith3SFace";
import PaymentDescription from "../components/home/PaymentDescription";
import Integration from "../components/home/Integration";
import FrameComponent2 from "../components/home/FrameComponent2";
import FrameComponent1 from "../components/home/FrameComponent1";
import FrameComponent from "../components/home/FrameComponent";

const Home = () => {
  const scrollToHowItWorks = () => {
    const element = document.getElementById('how-it-works');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="w-full relative bg-color-background-white overflow-hidden flex flex-col items-start justify-start gap-[125px] leading-[normal] tracking-[normal] mq825:gap-[31px] mq1250:gap-[62px] px-4 sm:px-6 md:px-8 lg:px-10">
      <section className="self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-[53px] box-border gap-[53px] max-w-full mq825:gap-[26px] mq825:pb-[34px] mq825:box-border">
        <FrameComponent3 onSeeHowItWorksClick={scrollToHowItWorks}/>
      </section>
      <div className="w-full max-w-[711px] flex flex-row items-start justify-center pt-0 px-5 pb-[123px] box-border">
        <PayWith3SFace />
      </div>
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[147px] box-border max-w-full">
        <div className="h-0 flex-1 relative max-w-full z-[1]" />
      </section>
      <div className="w-[9px] h-[9px] hidden md:block absolute !m-[0] top-[300px] right-[167px] rounded-[50%] bg-color-primary-bright-jade" />
      <div className="w-full max-w-[1222px] flex flex-row items-start justify-center pt-0 px-5 pb-[39px] box-border">
        <PaymentDescription />
      </div>
      <Integration />
      <FrameComponent2 />
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[50px] box-border max-w-full">
        <div className="h-0 flex-1 relative max-w-full" />
      </div>
      <FrameComponent1 />
      <FrameComponent />
    </div>
  );
};

export default Home;