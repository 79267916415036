import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import defaultAvatar from '../../pages/panel/images/user-avatar-80.png';
import { Button } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import LockReset from '@mui/icons-material/LockReset';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import api from '../../services/api';

function CompleteProfileForm() {
    const [feedback, setFeedback] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [localAvatar, setLocalAvatar] = useState('');
    const { getUser } = useAuth();
    const userData = getUser();
    const fileInputRef = React.useRef();

    // Add this useEffect to initialize localAvatar
    useEffect(() => {
        if (userData?.avatar) {
            setLocalAvatar(userData.avatar);
        }
    }, [userData]);
  
    const handleAvatarChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        try {
            setIsUploading(true);
            
            const reader = new FileReader();
            const base64Promise = new Promise((resolve) => {
                reader.onload = () => resolve(reader.result);
            });
            
            reader.readAsDataURL(file);
            const base64Image = await base64Promise;
    
            const { data } = await api.post('/users/avatar', {
                avatar: base64Image,
                userId: userData.id
            });
    
            if (data.success) {  // Check for success instead of avatarUrl
                setLocalAvatar(data.avatar);  // Use the avatar from response
                const newUserData = {
                    ...userData,
                    avatar: data.avatar
                };
                sessionStorage.setItem('userData', JSON.stringify(newUserData));
                setFeedback('Avatar updated successfully');
            }
        } catch (error) {
            console.error('Error uploading avatar:', error);
            setFeedback('Failed to upload avatar');
        } finally {
            setIsUploading(false);
        }
    };
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handlePasswordReset = async () => {
    // Get the current origin for the redirect URI
    const redirectUri = `${window.location.origin}`;
    
    // Construct the Azure AD B2C password reset URL
    // Replace these values with your actual Azure AD B2C tenant and policy names
    const tenant = "3shep";
    const policy = "B2C_1_pwd_reset";
    const clientId = process.env.REACT_APP_AZURE_B2C_CLIENT_ID;
    
    const b2cPasswordResetUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=id_token&redirect_uri=${encodeURIComponent(redirectUri)}&response_mode=fragment&scope=openid&nonce=${Math.random().toString(36)}`;

    // Redirect to the Azure AD B2C password reset page
    window.location.href = b2cPasswordResetUrl;
  };

  return (
    <div className="grow">
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">My Profile</h2>
        
        {/* Picture */}
        <section>
          <div className="flex items-center">
            <div className="mr-4">
            <img 
            className="w-20 h-20 rounded-full object-cover" 
            src={localAvatar || userData?.avatar || defaultAvatar} 
            width="80" 
            height="80" 
            alt="User avatar" 
            onError={(e) => {
                e.target.src = defaultAvatar;
            }}
        />
            </div>
            <div className="flex flex-col space-y-2">
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={handleAvatarChange}
              />
              <Button
                onClick={triggerFileInput}
                variant="contained"
                startIcon={<PhotoCamera />}
                size="small"
                disabled={isUploading}
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#3b82f6',
                  '&:hover': {
                    backgroundColor: '#2563eb',
                  }
                }}
              >
                {isUploading ? 'Uploading...' : 'Change'}
              </Button>
              {feedback && (
                <div className={`text-sm ${feedback.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                  {feedback}
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Profile Details */}
        <section>
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Profile Details</h2>
          <div className="text-sm">Manage your personal information.</div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">Full Name</label>
              <input 
                id="name" 
                className="form-input w-full bg-slate-50 cursor-not-allowed" 
                type="text" 
                value={userData?.name || ''} 
                disabled 
                readOnly
              />
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
              <input 
                id="email" 
                className="form-input w-full bg-slate-50 cursor-not-allowed" 
                type="email" 
                value={userData?.email || ''} 
                disabled 
                readOnly
              />
            </div>
          </div>
        </section>

        {/* Password */}
        <section>
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Password</h2>
          <div className="text-sm">You can set a permanent password if you don't want to use temporary login codes.</div>
          <div className="mt-5">
            <Button
              onClick={handlePasswordReset}
              variant="outlined"
              startIcon={<LockReset />}
              sx={{
                textTransform: 'none',
                borderColor: '#3b82f6',
                color: '#3b82f6',
                '&:hover': {
                  borderColor: '#2563eb',
                  backgroundColor: 'rgba(59, 130, 246, 0.04)',
                }
              }}
            >
              Reset Password
            </Button>
          </div>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
          <div className="flex self-end space-x-3">
            <Button
              variant="outlined"
              startIcon={<Cancel />}
              sx={{
                textTransform: 'none',
                borderColor: '#e5e7eb',
                color: '#4b5563',
                '&:hover': {
                  borderColor: '#d1d5db',
                  backgroundColor: 'rgba(75, 85, 99, 0.04)',
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              startIcon={<Save />}
              sx={{
                textTransform: 'none',
                backgroundColor: '#3b82f6',
                '&:hover': {
                  backgroundColor: '#2563eb',
                }
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default CompleteProfileForm;