import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FrameComponent4 from './home/FrameComponent4';
import { useAuth } from './AuthProvider'; // Update path as needed

const Navigation = () => {
  const { getUser, logout, isLoading } = useAuth();
  const userData = getUser();
  const navigate = useNavigate();

  // Public routes visible to all users
  const publicLinks = [
    { to: "/", label: "Home" },
    { to: "/about-us", label: "About Us" },
    { to: "/contact-us", label: "Contact" },
  ];

  // Auth routes only visible to logged-out users
  const authLinks = [
    { to: "/login", label: "Login" },
    //{ to: "/register", label: "Register" },
  ];

  // Protected routes based on user roles
  const protectedLinks = {
    admin: [
      { to: "/adminpanel", label: "Admin Panel" },
    ],
    merchant: [
      { to: "/panel", label: "Merchant Panel" },
    ],
    user: [
      { to: "/userpanel", label: "User Panel" },
    ],
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const renderNavLinks = () => {
    let links = [...publicLinks];

    if (!isLoading) {
      if (!userData) {
        links = [...links, ...authLinks];
      } else {
        // Add role-specific links based on user's role
        if (userData.role && protectedLinks[userData.role.toLowerCase()]) {
          links = [...links, ...protectedLinks[userData.role.toLowerCase()]];
        }
      }
    }

    return links.map(({ to, label }) => (
      <NavLink key={to} to={to}>
        {label}
      </NavLink>
    ));
  };

  // Optional loading state
  if (isLoading) {
    return (
      <FrameComponent4>
        <div className="flex flex-row items-center justify-end gap-[15px] max-w-full">
          {publicLinks.map(({ to, label }) => (
            <NavLink key={to} to={to}>
              {label}
            </NavLink>
          ))}
        </div>
      </FrameComponent4>
    );
  }

  return (
    <FrameComponent4>
      <div className="flex flex-row items-center justify-end gap-[15px] max-w-full">
        {renderNavLinks()}
        {userData && (
          <div className="flex items-center gap-4">
            <span className="text-sm font-medium">
              Welcome, {userData.name || userData.email}
            </span>
            <button
              onClick={handleLogout}
              className="rounded-6xs flex flex-row items-center justify-center p-2.5"
            >
              <span className="[text-decoration:none] relative tracking-[-0.02em] font-semibold text-[inherit] inline-block min-w-[45px] whitespace-nowrap">
                Logout
              </span>
            </button>
          </div>
        )}
      </div>
    </FrameComponent4>
  );
};

const NavLink = ({ to, children }) => (
  <div className="rounded-6xs flex flex-row items-center justify-center p-2.5">
    <Link
      to={to}
      className="[text-decoration:none] relative tracking-[-0.02em] font-semibold text-[inherit] inline-block min-w-[45px] whitespace-nowrap"
    >
      {children}
    </Link>
  </div>
);

export default Navigation;