import React, { useEffect, useMemo, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@remixicon/react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Badge,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
  Flex,
} from "@tremor/react";
import api from '../../services/api';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../components/AuthProvider';


const TrackingTable = () => {
  const { getUser } = useAuth();
  const userData = getUser();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const ordersPerPage = 5;

  useEffect(() => {
    const fetchPendingOrders = async () => {
      try {
        const response = await api.get(`/users/${userData.id}/orders/pending`);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching pending orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingOrders();
  }, [userData.id]);

  const data = useMemo(() => orders, [orders]);

  const columns = useMemo(
    () => [
      {
        header: "SR. NO.",
        accessorFn: (row, index) => index + 1,
        meta: { align: "text-left" },
        cell: (info) => (
          <div className="w-16">{info.getValue()}</div>
        ),
      },
      {
        header: "ORDER ID",
        accessorKey: "order_id",
        meta: { align: "text-left" },
        cell: (info) => (
          <div className="w-24">{info.getValue()}</div>
        ),
      },
      {
        header: "TRACKING ID",
        accessorKey: "tracking_number",
        meta: { align: "text-left" },
        cell: (info) => (
          <div className="w-32">{info.getValue()}</div>
        ),
      },
      {
        header: "DATE",
        accessorFn: (row) => new Date(row.createdAt).toLocaleString(),
        meta: { align: "text-left" },
        cell: (info) => (
          <div className="w-40">{info.getValue()}</div>
        ),
      },
      {
        header: "AMOUNT",
        accessorKey: "amount",
        meta: { align: "left" },
        cell: (info) => (
          <div className="w-24">AED {info.getValue()}</div>
        ),
      },
      {
        header: "STATUS",
        accessorKey: "status",
        meta: { align: "left" },
        cell: ({ getValue }) => (
          <div className="w-28">
            <Badge color={getStatusColor(getValue())}>{getValue()}</Badge>
          </div>
        ),
      },
      {
        header: "ACTION",
        meta: { align: "left" },
        cell: ({ row }) => (
          <div className="w-28">
            <Button
              variant="secondary"
              onClick={() => navigate(`/tracking/${row.original.tracking_number}/${row.original.order_id}`)}
            >
              Track Order
            </Button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: ordersPerPage,
      },
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="relative">
      <div className="sm:flex sm:justify-between sm:items-center mb-5">
        {/* Left side */}
        <div className="mb-4 sm:mb-0">
          <div className="m-1.5">
            <span className="text-sm">{table.getRowModel().rows.length} Orders</span>
          </div>
        </div>
      </div>
  
      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full divide-y divide-slate-200 dark:divide-slate-700">
          {/* Table header */}
          <thead className="text-xs uppercase text-slate-500 bg-slate-50">
  {table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <th key={header.id} className="table-header">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </th>
      ))}
    </tr>
  ))}
</thead>
<tbody className="text-sm divide-y divide-slate-200">
  {table.getRowModel().rows.map((row) => (
    <tr key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="table-cell">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  ))}
</tbody>
        </table>
      </div>
  
      {/* Pagination */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between px-5 py-3 border-t border-slate-200 dark:border-slate-700">
        <div className="text-sm text-slate-500 dark:text-slate-400">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <div className="flex justify-end mt-3 sm:mt-0">
          <button
            className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 disabled:opacity-50"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </button>
          <button
            className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 ml-2 disabled:opacity-50"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrackingTable;

function getStatusColor(status) {
  switch (status) {
    case "delivered":
      return "emerald";
    case "processing":
      return "blue";
    case "cancelled":
      return "red";
    default:
      return "gray";
  }
}