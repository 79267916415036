import PropTypes from "prop-types";

const Component1 = ({ className = "" }) => {
  return (
    <div
      className={`w-full max-w-[987px] mx-auto flex flex-col items-center justify-start pt-5 px-4 sm:px-6 md:px-8 pb-0 box-border gap-16 sm:gap-24 md:gap-32 lg:gap-[244px] text-center text-2xl sm:text-3xl md:text-4xl lg:text-21xl text-color-text-charcoal font-callout-body ${className}`}
    >
      <div className="w-full max-w-[681px] flex flex-col items-center justify-start gap-8">
        <div className="w-full max-w-[623.6px] relative">
          <div className="h-[158px] w-[158px] absolute top-[-20px] left-1/2 transform -translate-x-1/2 rounded-[50%] bg-lavender" />
          <img
            className="w-[205.6px] h-auto relative mx-auto z-[1]"
            loading="lazy"
            alt=""
            src="/group-1@2x.png"
          />
        </div>
        <div className="w-full rounded-t-4xs rounded-br-4xs rounded-bl-10xs flex flex-col items-start justify-start py-2.5 px-4 gap-4 z-[1]">
          <h2 className="m-0 w-full text-2xl sm:text-3xl md:text-4xl lg:text-21xl tracking-[-0.02em] font-bold">
            Accept delivery, and that's it!
          </h2>
          <div className="w-full text-base sm:text-lg md:text-xl lg:text-5xl tracking-[-0.02em] font-medium text-color-text-gray">
            Once you have received your purchase, your transaction is
            completed. Just check for any defects or issues and you're ready
            to for your next secure online transaction!
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-start gap-8 sm:gap-12 md:gap-16 lg:gap-[52px]">
        <div className="w-full max-w-[787px] flex flex-col items-center justify-start">
          <h1 className="m-0 w-full max-w-[530px] text-3xl sm:text-4xl md:text-5xl lg:text-45xl tracking-[-0.02em] font-bold text-transparent bg-clip-text bg-gradient-to-b from-white/50 to-transparent to-54.17% text-[#232629] flex items-center justify-center">
            Powerful backing
          </h1>
          <div className="w-full text-base sm:text-lg md:text-xl lg:text-5xl tracking-[-0.02em] font-medium mt-4">
            Our partnerships with Microsoft and Nvidia ensure that our
            customers' transactions will remain secure as technology develops.
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-8">
          <div className="w-full sm:w-[394px] h-auto">
            <img
              className="w-full h-auto object-contain"
              loading="lazy"
              alt=""
              src="/image-1@2x.png"
            />
          </div>
          <img
            className="w-full sm:w-[501px] h-auto object-contain"
            loading="lazy"
            alt=""
            src="/nvidiainceptionprogrambadgergbforscreen-1@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

Component1.propTypes = {
  className: PropTypes.string,
};

export default Component1;