import React, { useState } from 'react';
import SocialSignup from "./SocialSignup";
import Component1 from "./Component1";
import SignUpPopup from "../Auth/Popup/SignUpPopup";

const ModalBackdrop = ({ children, onClose }) => (
  <div
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}
  >
    {children}
  </div>
);

const FrameComponent = ({ className = "" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <section
        className={`self-stretch h-[1709px] relative max-w-full text-center text-base text-color-text-charcoal font-callout-body mq1250:h-auto mq1250:min-h-[1709] ${className}`}
      >
        <div className="absolute top-[850px] left-0 w-full h-[772px] flex justify-center">
          <SocialSignup className="z-[1]" />
          <div className="absolute left-1/2 -translate-x-1/2 flex flex-col items-center z-[5]" style={{ top: '200px' }}>
            <b className="text-13xl tracking-[-0.02em] text-transparent !bg-clip-text [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(255,_255,_255,_0)_54.17%),_#232629] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq825:text-7xl max-w-[90vw]">
              Want to stay in the loop?
            </b>
            <div className="mt-6 tracking-[-0.02em] font-medium max-w-[90vw] px-4">
              Sign up to join the waiting list to be among the first to get news and
              to know when we go live.
            </div>
            <button
              className="mt-6 cursor-pointer [border:none] py-2.5 px-5 bg-color-background-lapis rounded-6xs flex flex-row items-start justify-start gap-2.5 hover:bg-opacity-90 transition-all"
              onClick={handleOpenModal}
            >
              <div className="relative text-base tracking-[-0.02em] font-semibold font-callout-body text-color-background-white text-left">
                Join the waiting list
              </div>
              <div className="flex flex-col items-start justify-start pt-[2.5px] px-0 pb-0">
                <div className="w-3.5 flex flex-row items-center justify-center h-3.5">
                  <img className="h-3.5 w-2 relative" alt="" src="/vector-9.svg" />
                </div>
              </div>
            </button>
          </div>
        </div>
        <Component1 />
      </section>

      {isModalOpen && (
        <ModalBackdrop onClose={handleCloseModal}>
          <div onClick={e => e.stopPropagation()}>
            <SignUpPopup
              className="animate-fadeIn"
              onClose={handleCloseModal}
            />
          </div>
        </ModalBackdrop>
      )}
    </>
  );
};

export default FrameComponent;