import { useMemo } from "react";
import PropTypes from "prop-types";

const Card = ({
  className = "",
  vector,
  standardPaymentMethods,
  ourPlatformAllowsSeamless,
  propBackgroundColor,
}) => {
  const cardStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  return (
    <div
      className={`flex-1 shadow-[0px_0px_50px_rgba(0,_46,_66,_0.15)] rounded-2xs bg-color-background-lapis flex flex-col items-center justify-start pt-[30px] px-5 pb-[33px] box-border gap-[10px] min-w-[373px] max-w-full text-left text-13s text-color-background-white font-callout-body mq450:min-w-full ${className}`}
      style={cardStyle}
    >
      <div className="self-stretch flex flex-row items-center justify-center pt-0 px-0 pb-[15px]">
        <div className="flex flex-row items-center justify-center w-[37px] h-[37px]">
          <img className="h-[37px] w-[37px] relative" alt="" src={vector} />
        </div>
      </div>
      <b className="self-stretch relative tracking-[-0.02em] mq450:text-lgi mq825:text-7xl">
        {standardPaymentMethods}
      </b>
      <div className="self-stretch relative text-5xl tracking-[-0.02em] font-medium mq450:text-lgi">
        {ourPlatformAllowsSeamless}
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  vector: PropTypes.string,
  standardPaymentMethods: PropTypes.string,
  ourPlatformAllowsSeamless: PropTypes.string,

  /** Style props */
  propBackgroundColor: PropTypes.any,
};

export default Card;
