import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Button,
  Flex,
} from "@tremor/react";
import { UserIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import api from "../../../services/api"; // Adjust the import path to your api service

const UserTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = (userId) => {
    // Implement edit logic here
  };

  const handleDelete = (userId) => {
    // Implement delete logic here
  };

  return (
    <Card className="mt-4">
      <Title>Customers</Title>
      <Table className="mt-5">
        <TableHead>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Joined</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <Text>{user.id}</Text>
              </TableCell>
              <TableCell>
                <div className="flex items-center">
                  <UserIcon width={24} className="text-gray-500 mr-2" />
                  <Text>{user.name}</Text>
                </div>
              </TableCell>
              <TableCell>
                <Text>{user.email}</Text>
              </TableCell>
              <TableCell>
                <Text>{user.createdAt}</Text>
              </TableCell>
              <TableCell>
                <Flex justifyContent="between">
                  <Button
                    icon={PencilIcon}
                    size="xs"
                    onClick={() => handleEdit(user.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    icon={TrashIcon}
                    size="xs"
                    color="red"
                    onClick={() => handleDelete(user.id)}
                  >
                    Delete
                  </Button>
                </Flex>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserTable;